import React, { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";
import { Container } from "@material-ui/core";
import NoSleep from "nosleep.js";
import "./App.css";
import { useAuth0Combined } from "./hooks/useAuth0Combined";
import { useAppStatusContext } from "./utils/app-status-context";

import { useQueryJsonDataFactory } from "./queries/query-jsonData-factory";

/** Screens/Routes */
// import Map from './screens/Map'
import MapView from "./screens/Map";

/** Components */
import LoadingBackdrop from "./components/LoadingBackdrop";

let App = (props) => {
  /**
   * https://react-query.tanstack.com/guides/mutations
   * TODO: Offline caching of mutations will need to be done
   */
  const auth0 = useAuth0Combined();
  const {
    setShowLoading,
    processTasksheets,
    processOverlays,
  } = useAppStatusContext();
  /**
   * We don't actually use the data here but we want to fetch it so that
   * we have the data cached prior to usage.
   */
  const tasksheets = useQueryJsonDataFactory("tasksheets", auth0);
  const overlays = useQueryJsonDataFactory("overlays", auth0);
  // console.log('app.js settings => ', settings)

  useEffect(() => {
    setShowLoading && setShowLoading(false);
  }, [auth0.isLoading]);

  useEffect(() => {
    setShowLoading && setShowLoading({ message: "Loading..." });
  }, [setShowLoading]);

  useEffect(() => {
    processTasksheets && processTasksheets(tasksheets.data);
    processOverlays && processOverlays(overlays.data);
  }, [tasksheets.isSuccess, overlays.isSuccess]);

  var noSleep = new NoSleep();
  // Enable wake lock.
  // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
  // We have to do some juggling to get this re-added if the user leaves and comes back
  // https://github.com/richtr/NoSleep.js/issues/81
  document.addEventListener("pointerover", function enableNoSleep() {
    document.removeEventListener("pointerover", enableNoSleep);
    noSleep.enable();
  });
  document.addEventListener("visibilitychange", function () {
    handleVisibilityChange(noSleep);
  });
  function handleVisibilityChange(noSleep) {
    console.log("handleVsibilityChange");
    // if (document.hidden) {
    //   console.log('document hidden')
    //   try {
    //     console.log('disabling sleep')
    //     noSleep.disable()
    //     console.log('disabled nosleep')
    //   }
    //   catch {
    //     console.log('adding listener for nosleep')
    //     document.addEventListener('pointerdown', function enableNoSleep () {
    //       document.removeEventListener('pointerdown', enableNoSleep)
    //       console.log('enabling nosleep catch statement')
    //       noSleep.enable()
    //       console.log('enabled nosleep catch statement')
    //     })
    //   }
    // } else {
    //   console.log('document visible adding listener')
    //   document.addEventListener('pointerdown', function enableNoSleep () {
    //     document.removeEventListener('pointerdown', enableNoSleep)
    //       console.log('enabling nosleep nocatch')
    //     noSleep.enable()
    //     console.log('enabled nosleep nocatch')
    //   })
    // }
  }

  // const loading = (auth0Loading || !props.settings) || (!props.flights || !props.flights.loaded) || (!props.tasksheets || !props.tasksheets.loaded)
  // const loading = (auth0Loading || !props.settings)
  return (
    <Container className={`App`} disableGutters={true} maxWidth={false}>
      <LoadingBackdrop />
      <BrowserRouter>
        {/* } */}
        {/* Might be able to remove this auth0UserInfo pass and just use the custom hook? */}
        <MapView />
      </BrowserRouter>
    </Container>
  );
};

export default App;
