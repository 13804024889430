import React from 'react'
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  settingsOpAreaImg: {
    width: '100%',
  }
}));

const Help = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography variant="h2">
        Help
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="settings-content"
          id="settings-header"
        >
          <Typography>Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The app willload satellite tiles for whatever your "operating area" is. Setting your operating area is done through the "Settings" area in the menu.
            <img class={classes.settingsOpAreaImg} src="settingsOperatingArea.png" />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  )
}

export default Help