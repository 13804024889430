import React from 'react'
import FlightForm from '../components/FlightForm'
import {
  useParams
} from "react-router-dom";

const FlightEdit = (props) => {
  const { id } = useParams()
  return (
    <React.Fragment>
      <FlightForm 
        id={id}
      />
    </React.Fragment>
  )
}

export default FlightEdit 