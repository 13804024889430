import React from 'react'
import { taskAlphaPrefixes } from './constants';
import { TaskMarker } from '../../components/Marker';
import { utmToLatLng } from '../../utils/utm';

export const MapTaskToolMarker = ({
  task,
  tool,
  mapToolKey,
}) => {
  let markers = [];

  if (tool.points.length === 0) return;
  tool.points.forEach((point, index) => {
    if (!point || !point.easting || !point.northing) return;
    const { easting, northing, zone, band } = point;
    if (!easting || !northing || !zone || !band) return;

    const { lat, lng } = utmToLatLng(point);
    if (!lat || !lng) return

    let rings = [];
    if (point.rings) {
      rings = point.rings.split(',');
    }

    const key = mapToolKey + zone + easting + northing;
    const labelPrefix = tool.points.length > 1 ? ` - ${taskAlphaPrefixes[index]}` : '';
    const label = 
      task.number || task.name ? 
        `${task.number ? task.number + ':' : ' '}${task.name ? task.name : ''}${labelPrefix}` 
        : `TASK${labelPrefix}`;

    markers.push(
      <TaskMarker
        key={key}
        lat={lat}
        lng={lng}
        color={task.color}
        label={label}
        notes={task.notes}
        markerDrop={task.markerDrop}
        rings={rings}
      />
    );
  });

  return markers;
}
