import React from 'react'
import { useAuth0Combined } from '../hooks/useAuth0Combined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import StarsIcon from '@material-ui/icons/Stars';
import EditIcon from '@material-ui/icons/Edit';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom'
import { useQueryJsonDataFactory } from '../queries/query-jsonData-factory';
import { nanoid } from 'nanoid/non-secure';

let TasksheetList = (props) => {
  const auth0 = useAuth0Combined();
  const { data: tasksheets, ...tasksheetsQuery } = useQueryJsonDataFactory('tasksheets', auth0);

  return (
    <List component="nav">
      <NavLink to={`/tasksheets/edit/${nanoid(11)}`}>
        <ListItem dense button>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={'Create new tasksheet'} />
        </ListItem>
      </NavLink>
      {/*TODO: sort by active */}
      {tasksheetsQuery.isSuccess && Object.entries(tasksheets.data).map(([key, value]) => {

        if (!value || tasksheetsQuery.isLoading || !tasksheetsQuery.isSuccess) return '';
        const listItemLabel = value.doc.name || value.doc.date
        const icon = value.doc.isActive ? <StarsIcon /> : <EditIcon />
        return (
          <NavLink key={value.id} to={`/tasksheets/edit/${value.id}`}>
            <ListItem dense button>
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={`${listItemLabel}`} />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  )
}

export default TasksheetList