import React from "react";
import ReactDOM from "react-dom";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
// import { composeWithDevTools } from 'redux-devtools-extension';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import api from './utils/api'

import { AppStatusProvider } from "./utils/app-status-context";
import { UserLocationProvider } from "./hooks/useUserLocation";

import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

/** React Query */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      /**
       * It's unlikely to need the data to be refreshed
       * and probably MORE likely to cause problems if the data can't be retrieved and has been
       * garbage collected.
       * At this point any updates to the server should only be coming from the specific users
       * computer anyways.
       * If we allow updates to happen from a remote computer we might want those updates, but
       * that's very much a later TODO.
       *
       */
      cacheTime: 1000 * 60 * 300,
    },
  },
});

/** Offline config */
// const effect = (effect, _action) => api(effect);
// const discard = (error, _action, _retries) => {
//   const { request, response } = error;
//   if (!request) throw error; // There was an error creating the request
//   if (!response) return false; // There was no response
//   return 400 <= response.status && response.status < 500;
// };
/** Redux config start */
// const offlineConfig = {
//   ...offlineDefaultConfig,
//   effect,
//   discard
// }

// const composeEnhancers = composeWithDevTools(options);
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const store = createStore(reducers, /* preloadedState, */ composeEnhancers(
//   // applyMiddleware(...middleware),
//   applyMiddleware(thunk),
//   offline(offlineConfig)
//   // other store enhancers if any
// ));
/** Redux config end */

ReactDOM.render(
  // StrictMode is causing a warning about setting state on unmounted component for google-map-react
  // https://github.com/google-map-react/google-map-react/issues/861
  // https://github.com/google-map-react/google-map-react/issues/677
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>,
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    client_id={process.env.REACT_APP_AUTH0_CLIENTID}
    audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <QueryClientProvider client={queryClient}>
      <AppStatusProvider>
        <UserLocationProvider>
          <App />
          <ReactQueryDevtools initialIsOpen />
        </UserLocationProvider>
      </AppStatusProvider>
    </QueryClientProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
