import React, { useState, useEffect } from "react";
import { useAuth0Combined } from "../hooks/useAuth0Combined";
// import { overlayCreate, overlayUpdate, overlayDelete } from '../ducks/overlays';
// import { showLoading, hideLoading } from '../ducks/loading';
import { Redirect } from "react-router-dom";
import ReactS3Uploader from "react-s3-uploader";
import Snackbar from "@material-ui/core/Snackbar";

import { makeStyles } from "@material-ui/core/styles";
// import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
// import MenuItem from '@material-ui/core/MenuItem';
// import ConfirmationDialog from './ConfirmationDialog'
// import TrackList from './TrackList'

import Typography from "@material-ui/core/Typography";
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Divider from '@material-ui/core/Divider';
// import Paper from '@material-ui/core/Paper';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { TextField, Switches } from "mui-rff";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { useQueryClient } from "react-query";
import {
  useQueryJsonDataFactory,
  useMutateJsonDataFactory,
} from "../queries/query-jsonData-factory";
import { useAppStatusContext } from "../utils/app-status-context";
import { nanoid } from "nanoid/non-secure";

const useStyles = makeStyles((theme) => ({
  overlayFormRoot: {
    width: "300px",
    display: "flex",
    // flexWrap: 'wrap',
    "& > *": {
      margin: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    },
  },
  fileUpload: {
    margin: "16px 0 0",
    "& > input": {
      marginTop: "20px",
    },
  },
}));

let OverlayForm = (props) => {
  const auth0 = useAuth0Combined();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const { setShowLoading } = useAppStatusContext();
  const [goBack, setGoBack] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const snackbarOpen = snackbarMessage ? true : false;
  const { data: overlays, ...overlaysQuery } = useQueryJsonDataFactory(
    "overlays",
    auth0
  );
  const overlaysMutate = useMutateJsonDataFactory(
    "overlays",
    auth0,
    queryClient
  );
  const [overlayIndex, setOverlayIndex] = useState(null);
  const [overlayID] = useState(props.id ? props.id : nanoid(11));
  const { addActiveOverlay, removeActiveOverlay } = useAppStatusContext();

  useEffect(() => {
    if (!overlaysQuery.isSuccess || !overlays) return;
    const overlayIndex = overlays.data.findIndex(
      (entry) => entry.id === overlayID
    );
    setOverlayIndex(overlayIndex);
  }, [overlaysQuery, overlayID, overlays]);

  const onSubmit = async (values) => {
    const url = fileUrl && fileUrl ? fileUrl : values.url;
    const valuesWithUrl = {
      ...values,
      url: url,
    };
    const data = { id: overlayID, doc: valuesWithUrl };
    // If we have an id we are modifying an existing flight
    if (overlayIndex > -1) {
      await overlaysMutate.mutateAsync({ action: "update", data }, auth0);
      // TODO: figure out how to delete old files
      // show toast
      setSnackbarMessage("Overlay saved.");
    } else {
      await overlaysMutate.mutateAsync({ action: "create", data }, auth0);
      // show toast
      setSnackbarMessage("Overlay created.");
    }
    if (values.isActive) {
      addActiveOverlay(data);
    } else {
      removeActiveOverlay(data);
    }
  };

  const onGoBack = async () => {
    setGoBack(true);
  };

  const onDelete = async () => {
    const data = { id: overlayID };
    await overlaysMutate.mutateAsync({ action: "delete", data }, auth0);
    removeActiveOverlay(data);
    setGoBack(true);
  };

  const onUploadFinish = (data, file) => {
    const publicUrl = process.env.REACT_APP_API_HOST + data.publicUrl;
    console.log(publicUrl);
    // TODO: how can I get this to show on map temporarily
    setFileUrl(publicUrl);
    setSnackbarMessage("File upload finished.");
    setShowLoading(false);
  };

  const onUploadError = (data, file, ...rest) => {
    console.log("uploadError");
    console.log(data);
    console.log(rest);
    debugger;
    setShowLoading(false);
    setSnackbarMessage("File upload error.");
  };

  // eslint-disable-next-line no-unused-vars
  const onUploadStart = (...rest) => {
    console.log("uploadStart");
    console.log(rest);
    setSnackbarMessage("File upload started.");
    setShowLoading({ progress: 0 });
  };

  const onUploadProgress = (progress, message) => {
    console.log("uploadprogress");
    setShowLoading({ progress, message });
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarMessage("");
  };

  if (goBack) {
    // show toast
    return <Redirect to="/overlays" />;
  }

  if (!overlaysQuery.isSuccess) return "Loading overlay...";
  if (overlaysQuery.isError) return "Failed to load overlay";
  // If we don't have overlays and we're looking for a specific ID don't load yet
  if (overlayIndex === null) return "Overlay not found";

  const initialValues =
    (overlays &&
      overlayIndex > -1 &&
      overlays.data[overlayIndex] &&
      overlays.data[overlayIndex].doc) ||
    null;
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <div className={classes.overlayFormRoot}>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            form: {
              mutators: { push, pop },
            },
            form,
            submitting,
            pristine,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField label="Name" name="name" required={true} />
              <TextField
                label="Tags"
                helperText="Separated by commas"
                name="tags"
              />
              <TextField label="Description" name="description" multiline />
              <TextField label="Track Color" name="trackColor" />
              <TextField
                label="Marker Color - Background"
                name="markerColorBG"
              />
              <TextField label="Marker Color - Text" name="markerColorText" />
              <Typography variant="subtitle2">
                Colors will only apply to GPX files.
              </Typography>
              <FormControl fullWidth className={classes.fileUpload}>
                <InputLabel style={{}} htmlFor="file" shrink>
                  File
                </InputLabel>
                <ReactS3Uploader
                  name="file"
                  signingUrl="/s3/sign"
                  signingUrlMethod="GET"
                  s3path="overlays/"
                  // preprocess={onUploadStart}
                  // onSignedUrl={onSignedUrl}
                  onProgress={onUploadProgress}
                  onError={onUploadError}
                  onFinish={onUploadFinish}
                  // signingUrlHeaders={{ additional: headers }}
                  // signingUrlQueryParams={{ additional: query-params }}
                  // signingUrlWithCredentials={ true }      // in case when need to pass authentication credentials via CORS
                  // uploadRequestHeaders={{ "x-amz-acl": "public-read" }} // this is the default
                  uploadRequestHeaders={{}} // this is the default
                  contentDisposition="auto"
                  scrubFilename={(filename) =>
                    filename.replace(/[^\w\d_\-.]+/gi, "")
                  }
                  server={process.env.REACT_APP_API_HOST}
                  // inputRef={cmp => this.uploadInput = cmp}
                  autoUpload={true}
                />
              </FormControl>

              {initialValues && initialValues.url ? (
                <TextField
                  label="URL"
                  name="url"
                  helperText="URL is readonly"
                  disabled
                />
              ) : (
                <TextField
                  label="URL"
                  name="url"
                  helperText="URL is readonly"
                  disabled
                  value={fileUrl}
                />
              )}

              <Switches
                name="isActive"
                data={{ label: "Active Overlay?", value: true }}
              />

              <div className="buttons">
                <button type="submit" disabled={submitting}>
                  Submit
                </button>
                <button type="button" onClick={onGoBack}>
                  Go Back
                </button>
                <button type="button" onClick={onDelete}>
                  Delete
                </button>
              </div>
              {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
            </form>
          )}
        />
      </div>
    </React.Fragment>
  );
};

export default OverlayForm;
