import React from 'react'
import SettingsForm from '../components/SettingsForm'

const Settings = () => {
  return (
    <React.Fragment>
      <SettingsForm />
    </React.Fragment>
  )
}

export default Settings 