
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Utm as MgrsUtm, LatLon as MgrsLatLon } from 'geodesy/mgrs.js';
import Utm from 'geodesy/utm.js';
import { roundNumber } from './numberUtil';
import L from 'leaflet';

const BOUNDS_SIZE = 30000;

const useStyles = makeStyles((theme) => ({
  highlightedUTM: {
    fontSize: '1.4em',
    margin: '0 1px'
  }
}));

// export const highlightAs4x4 = (num, isEasting = true) => {
export const Highlighted4x4 = ({coordinates, isEasting = true}) => {
  const classes = useStyles();
  if (isEasting) {
    return (
      <span>
        {String(coordinates).substring(0,1)}
        <strong className={classes.highlightedUTM}>{String(coordinates).substring(1,5)}</strong>
        {String(coordinates).substring(5)}
        E
      </span>
    );
  }
  else {
    return (
      <span>
        {String(coordinates).substring(0,2)}
        <strong className={classes.highlightedUTM}>{String(coordinates).substring(2, 6)}</strong>
        {String(coordinates).substring(6)} 
        N
      </span>
    );
  }
};

const isPointInOperatingBounds = (settings, point) => {
  return L.latLng(utmToLatLng(settings.data.operatingLocation)).toBounds(BOUNDS_SIZE).contains(L.latLng(utmToLatLng(point)));
};

export const convert4x4ToUtm = ({settings, point}) => {
  if (!point || point.easting4x4 === undefined || point.northing4x4 === undefined) return;
  const operatingLocation = settings.data.operatingLocation ? settings.data.operatingLocation : {};
  const operatingEasting = operatingLocation.easting.substring(0,1);
  const operatingNorthing = operatingLocation.northing.substring(0,2);
  point.band = operatingLocation.band;
  point.zone = operatingLocation.zone;
  // Only modify our easting/northing if they are 4x4 formats
  if (point.easting4x4 && point.easting4x4.length === 4) {
    point.easting = operatingEasting + prependZero(point.easting4x4);
  }
  else if (point.easting4x4 && point.easting4x4.length === 6 || point.easint4x4.length === 7) {
    point.easting = point.easting4x4;
  }
  if (point.northing4x4 && point.northing4x4.length === 4) {
    point.northing = operatingNorthing + prependZero(point.northing4x4);
  }
  else if (point.northing4x4 && point.northing4x4.length === 7) {
    point.northing = point.northing4x4;
  }

  // let pointCandidate = L.latLng(utmToLatLng(point));

  
  let pointIsInBounds = isPointInOperatingBounds(settings, point);
  if (!pointIsInBounds && point.northing) {
    if (point.northing.slice(0,1) < 5) {
      // Increase our northing
      point.northing = (Number(operatingNorthing) + 1) + prependZero(point.northing4x4);
      pointIsInBounds = isPointInOperatingBounds(settings, point);
    }
    else {
      // Decrease our northing
      point.northing = (Number(operatingNorthing) - 1) + prependZero(point.northing4x4);
      pointIsInBounds = isPointInOperatingBounds(settings, point);
    }
  }
  if (!pointIsInBounds && point.easting) {
    // Reset northing
    point.northing = operatingNorthing + prependZero(point.northing4x4);
    if (point.easting.slice(0,1) < 5) {
      // Increase our easting
      point.easting = (Number(operatingEasting) + 1) + prependZero(point.easting4x4);
      pointIsInBounds = isPointInOperatingBounds(settings, point);
    }
    else {
      // Decrease our easting
      point.easting = (Number(operatingEasting) - 1) + prependZero(point.easting4x4);
      pointIsInBounds = isPointInOperatingBounds(settings, point);
    }
  }
  
  
  // L.latLng(utmToLatLng(settings.data.operatingLocation)).toBounds(30000).contains(L.latLng(utmToLatLng({zone: '15', band: 'S', easting: '331690', northing: '3502560'})))

  // debugger
  
  /**
   * 0203 / 2341
   * 002030 / 0023410
   * 
   * 402030 / 3523410
   * 
   * 302030 / 3523410
   * 502030 / 3523410
   * 
   * 402030 / 3623410
   * 402030 / 3423410
   */

  return point;
};

export const convertLatLngTo4x4 = (latlng) => {
  const utm = { coord: latLngToUtm(latlng) };
  return convertUtmTo4x4(utm);

};

export const convertUtmTo4x4 = ({coord}) => {
  console.log('convering utm to 4x4')
  // TODO: check for easting.length === 7 because that could happen
  if (coord && coord.easting && coord.easting.length === 6) {
    console.log('converted easting to 4x4')
    coord.easting = coord.easting.substring(1,5);
  }
  if (coord && coord.northing && coord.northing.length === 7) {
    console.log('converted northing to 4x4')
    coord.northing = coord.northing.substring(2,6);
  }

  console.log(coord)
  return coord;
};

/**
 * The UTM format that is used seems to be a UTM with zone bands.
 * Bands are only present in MGRS formats so we pull the band from there and send back everything.
 * Pads easting/northing values to make sure we always have enough digits to display for valid 4x4 highlights.
 * 
 * UTM coordinates should be rounded. MGRS should be truncated. https://github.com/chrisveness/geodesy/issues/73#issuecomment-583758644
 * "the MGRS easting (within the 100km grid square) is 77503.623645 (477503.623645 for the UTM easting, from the central meridian). To 1-metre precision, these get truncated/rounded to 77503 & 477504."
 * 
 * @param {LatLng} latLng 
 * @returns 
 */
export const latLngToUtm = (latLng) => {
  try {
    const utm = new MgrsLatLon(latLng.lat, latLng.lng).toUtm();
    const mgrs = new MgrsUtm(utm.zone, utm.hemisphere, utm.easting, utm.northing).toMgrs();

    // console.log('mgrs')
    // console.log(mgrs.toString())
    // We send back a UTM with a band
    return { zone: utm.zone, hemisphere: utm.hemisphere, band: mgrs.band, easting: padEasting(utm.easting), northing: padNorthing(utm.northing) };
  }
  catch (error) {
    console.error("ERROR: could not parse `latLngToUtm`")
    console.error(error)
  }
  return {};
}

const prependZero = (coord) => {
  return coord + "0";
};

const padEasting = (easting) => {
  const formattedEasting = roundNumber(easting);
  return pad(formattedEasting, 6);
}

const padNorthing = (northing) => {
  const formattedNorthing = roundNumber(northing);
  return pad(formattedNorthing, 7);
}

function pad(n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

/**
 * Converts a UTM coordinate with latitude band to LatLng.
 * 
 * @param {Utm} coords - This will have a latitude band defined instead of N/S hemispehere
 */
export const utmToLatLng = (coord) => {
  try {
    const { easting, northing, zone, band } = coord
    if (!easting || !northing || !zone || !band) return {lat: 0, lng: 0};

    const isSouthHemisphere = (band) => /[A-M]/.test(band);
    const hemisphere = isSouthHemisphere(band) ? 'S' : 'N';

    if (
      (!easting || easting.length > 7) ||
      (!northing || northing.length > 7)
    ) {
      return '';
    }
    const utm = new Utm(zone, hemisphere, easting, northing);
    const latLng = utm.toLatLon();

    return { lat: latLng.lat, lng: latLng.lon };
  }
  catch (error) {
    console.error("ERROR: could not parse `utmToLatLng`")
    console.error(error)
  }
  return {};
};
