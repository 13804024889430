import React, { useState, useEffect } from 'react';
import { useAuth0Combined } from '../hooks/useAuth0Combined';
import { Redirect } from 'react-router-dom'
import { useQueryClient } from 'react-query';
import { useQueryJsonDataFactory, useMutateJsonDataFactory } from '../queries/query-jsonData-factory';
import { useQueryFactory } from '../queries/query-factory';
import { useMap } from 'react-leaflet';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AutoSave from './AutoSave'

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { TextField, Switches, DatePicker } from 'mui-rff';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { nanoid } from 'nanoid/non-secure';
import { useAppStatusContext } from '../utils/app-status-context';
import { convert4x4ToUtm } from '../utils/utm';

import FormTaskToolMarker from './FormTaskTools/FormTaskToolMarker';
import FormTaskToolPolyline from './FormTaskTools/FormTaskToolPolyline';
import FormTaskToolPolygon from './FormTaskTools/FormTaskToolPolygon';
import FormTaskToolRing from './FormTaskTools/FormTaskToolRing';

const formTaskToolComponents = {
  marker: FormTaskToolMarker,
  polyline: FormTaskToolPolyline,
  polygon: FormTaskToolPolygon,
  ring: FormTaskToolRing,
};

const useStyles = makeStyles((theme) => ({
  buttons: {
    position: 'fixed',
    top: 0,
    left: 80,
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: 'rgb(255, 255, 255)'
    }
  },
  spacerButton: {
    width: 100,
  },
  tasksheetRoot: {
    width: 400,
    marginTop: '20px',
    marginBottom: '400px'
  },
  tasksheet: {
    marginTop: 0
  },
  task: {
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  coordinates: {
    backgroundColor: 'rgba(200,200,200,.7)',
    '& > *': {
      padding: theme.spacing(1),
    },
  },
  toolIcons: {
    color: '#1f59e0',
    cursor: 'pointer',
  },
  toolIconsDelete: {
    color: '#d23232',
    cursor: 'pointer',
  },
}));

/**
 * Convert 4x4 values to full 6/7 values.
 * @param {*} tasks 
 * @param {*} settings 
 * @param {*} map 
 * @param {*} callback 
 * @returns 
 */
const modifyTasks = (tasks, settings, callback) => {
  if (tasks && tasks.length > 0) {
    tasks.forEach(task => {
      if(task && task.tools) {
        task.tools.forEach(tool => {
          if (!tool) return;
          tool.points.forEach((point) => {
            if (!point) return;
            point = callback({settings, point});
          })
        });
      }
    });
  }
  return tasks;
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let TasksheetForm = (props) => {
  const classes = useStyles();
  const auth0 = useAuth0Combined()
  const queryClient = useQueryClient()
  const [goBack, setGoBack] = useState(false);
  const [ snackbarMessage, setSnackbarMessage ] = useState('')
  const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
  const snackbarOpen = snackbarMessage ? true : false
  const {data: tasksheets, ...tasksheetsQuery } = useQueryJsonDataFactory('tasksheets', auth0);
  const tasksheetsMutate = useMutateJsonDataFactory('tasksheets', auth0, queryClient);
  const { data: settings } = useQueryFactory('settings', auth0);
  const [ tasksheetIndex, setTasksheetIndex ] = useState(null);
  const [ tasksheetID ] = useState(props.id ? props.id : nanoid(11));
  const { addActiveTasksheet, removeActiveTasksheet } = useAppStatusContext();
  const foundTasksheet = (tasksheets && tasksheetIndex > -1 && tasksheets.data[tasksheetIndex] && tasksheets.data[tasksheetIndex].doc) || null;
  // console.log(`tasksheet ID => ${tasksheetID}`)
  

  useEffect(() => {
    if (!tasksheetsQuery.isSuccess || !tasksheets) return;
    const tasksheetIndex = tasksheets.data.findIndex((entry) => entry.id === tasksheetID);
    setTasksheetIndex(tasksheetIndex);
  }, [tasksheetsQuery, tasksheetID, tasksheets]);

  const onSubmit = async values => {
    // console.log('submit')
    values.tasks = modifyTasks(values.tasks, settings, convert4x4ToUtm);
    const data = {id: tasksheetID, doc: values};
    if (tasksheetIndex > -1) {
      // If we have an id we are modifying an existing flight
      // props.tasksheetUpdate(auth0, id, values)
      await tasksheetsMutate.mutate({action: 'update', data }, auth0);
      // show toast
      setSnackbarMessage('Tasksheet saved.')
    }
    else {
      // const response = await props.tasksheetCreate(auth0, values)
      const response = await tasksheetsMutate.mutate({action: 'create', data }, auth0);
      console.log('response!')
      console.log(response)
      // show toast
      setSnackbarMessage('Tasksheet created.')
      // TODO: how to get response.id?
      // setFormId(response.id)
    }
    if (values.isActive) {
      addActiveTasksheet(data);
    }
    else {
      removeActiveTasksheet(data);
    }
  }

  const handleClickToolMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseToolMenu = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuClick = (e, push, name) => {
    const type = e.currentTarget.getAttribute('name');
    // const name = e.currentTarget.getAttribute('data-taskname');
    console.log('menuClick')
    console.log(e.currentTarget)
    console.log(type)
    console.log(name)

    // push(`${name}.tools`, {shapetype: type, easting4x4: '0000'});
    push(`${name}.tools`, {shapetype: type});
    setMenuAnchorEl(null);
  };

  const onGoBack = async () => {
    setSnackbarMessage('Going back.')
    setGoBack(true)
  }

  const onDelete = async () => {
    // props.tasksheetDelete(auth0, props.id)
    const data = {id: tasksheetID};
    tasksheetsMutate.mutateAsync({action: 'delete', data}, auth0);
    setSnackbarMessage('Tasksheet deleted.')
    removeActiveTasksheet(data);
    setGoBack(true)
  }

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarMessage('')
  }

  if (
    goBack
  ) {
    // show toast
    const url = '/tasksheets'
    return <Redirect to={url} />
  }

  if (!tasksheetsQuery.isSuccess) return 'Loading tasksheet...';
  if (tasksheetsQuery.isError) return 'Failed to load tasksheet';
  // If we don't have overlays and we're looking for a specific ID don't load yet
  if (tasksheetIndex === null) return 'Tasksheet not found'
  const initialValues = foundTasksheet;
  return (
    <div className={classes.tasksheetRoot}>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        autoHideDuration={5000}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      >
        <Alert severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators
      }}
      render={({ 
        handleSubmit,
        form: {
          mutators: {
            push,
            pop,
          }
        },
        form,
        submitting,
        pristine,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <AutoSave debounce={5000} save={onSubmit} />
          <Grid className={classes.tasksheet} container spacing={3}>
            <Grid item xs={4}>
              <TextField 
                label="Name" 
                name="name"
                required={true}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={5}>
              <DatePicker
                label="Date"
                name="date"
                format="dd/MM/yyyy"
                dateFunsUtils={DateFnsUtils}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={1}>
              
              <FormControlLabel
                control={<Switches
                  name="isActive"
                  data={{value: true}}
                />}
                label="Active"
                labelPlacement="top"
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h5" component="h2">
                Tasks
              </Typography>
            </Grid>
            
            <FieldArray name="tasks">
              {({ fields }) =>
                fields.map((name, index) => {
                  const color = fields.value[index] ? nameToRGBA(fields.value[index].color) : ''

                  return(
                    <Grid key={name} item xs={12}>
                      <Paper
                        className={classes.task}
                        variant="outlined"
                        // style={{ backgroundColor: color }}
                        style={{ boxShadow: `inset 0em 0em .5em .1em ${color}`}}
                      >
                        
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <IconButton
                              onClick={() => fields.remove(index)}
                              // onDoubleClick={() => fields.remove(index)}
                              className={classes.toolIconsDelete}
                              aria-label={`Remove Task`}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                          <Grid item xs={10}>
                          </Grid>
                        <Grid item xs={3}>
                          <TextField
                            label="Number"
                            name={`${name}.number`}
                            size="small"
                            variant="outlined"
                            inputMode="numeric"
                            type="tel"
                            pattern="[0-9]*"
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            label="Name"
                            name={`${name}.name`}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <TextField
                            label="Task Color"
                            name={`${name}.color`}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Open Time"
                            name={`${name}.openTime`}
                            size="small"
                            variant="outlined"
                            type="tel"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Close Time"
                            name={`${name}.closeTime`}
                            size="small"
                            variant="outlined"
                            type="tel"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Marker Drop"
                            name={`${name}.markerDrop`}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            label="Altitude"
                            name={`${name}.altitude`}
                            size="small"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Notes"
                            name={`${name}.notes`}
                            multiline
                            size="small"
                            variant="outlined"
                          />
                        </Grid>

                        {/* TODO: Add option to add Coordinates from saved list */}
                        <Grid item xs={3}>
                          <Typography variant="h6" component="h6">
                            Tools
                          </Typography>
                        </Grid>

                        <Grid item xs={9}>
                          <Button 
                            variant="contained"
                            size="small"
                            onClick={(e) => handleMenuClick(e, push, name)}
                            name="marker"
                          >
                            +Marker
                          </Button>
                          <Button 
                            variant="contained"
                            size="small"
                            onClick={(e) => handleMenuClick(e, push, name)}
                            name="polygon"
                          >
                            +Polygon
                          </Button>
                          <Button 
                            variant="contained"
                            size="small"
                            onClick={(e) => handleMenuClick(e, push, name)}
                            name="polyline"
                          >
                            +Polyline
                          </Button>
                        </Grid>
                        <FieldArray name={`${name}.tools`}>
                          {({ fields }) =>
                            fields.map((name, index) => {
                              const fieldValues = fields.value[index];
                              const shapetype = fieldValues.shapetype;
                              // let FormTaskToolComponent = <FormTaskToolMarker />;
                              const FormTaskToolComponent = formTaskToolComponents[shapetype];

                              return (

                                <Grid key={name} item xs={12} >

                                <FormTaskToolComponent
                                  name={name}
                                  type={shapetype}
                                  fields={fields}
                                  index={index}
                                  classes={classes}
                                  form={form}
                                  push={push}
                                />
                                </Grid>
                            )
                          })
                          }
                        </FieldArray>
                        </Grid>
                      </Paper>
                    </Grid>
                  )
                })
              }
            </FieldArray>
          <Grid item xs={12}>
            <Button
              variant="contained"
              size="small"
              onClick={() => push('tasks', undefined)}
            >
              Add Task
            </Button>
          </Grid>
         
          <ButtonGroup
            className={classes.buttons}
            variant="contained"
            color="primary"
            size="large"
          >
            <Button
              type="submit"
            >
              Save
            </Button>
            <Button
              onClick={()=>{}}
              className={classes.spaceButton}
            />
            <Button
              onClick={onDelete}
              // onDoubleClick={onDelete}
            >
              Delete
            </Button>
          </ButtonGroup>
          </Grid>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
    </div>
  )
};

// You have to connect() to any reducers that you wish to connect to yourself
export default TasksheetForm;

function hexAToRGBA(h) {
  let r = 0, g = 0, b = 0, a = 1;

  if (h.length === 5) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
    a = "0x" + h[4] + h[4];

  } else if (h.length === 9) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
    a = "0x" + h[7] + h[8];
  }
  a = +(a / 255).toFixed(3);

  return "rgba(" + +r + "," + +g + "," + +b + "," + a + ")";
}

function nameToHex(name) {
  // Get RGB from named color in temporary div
  let fakeDiv = document.createElement("div");
  fakeDiv.style.color = name;
  document.body.appendChild(fakeDiv);

  let cs = window.getComputedStyle(fakeDiv),
      pv = cs.getPropertyValue("color");

  document.body.removeChild(fakeDiv);

  // Code ripped from RGBToHex() (except pv is substringed)
  let rgb = pv.substr(4).split(")")[0].split(","),
      r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

  if (r.length === 1)
    r = "0" + r;
  if (g.length === 1)
    g = "0" + g;
  if (b.length === 1)
    b = "0" + b;

  return "#" + r + g + b;
}

function nameToRGBA(name) {
  const transparency = name ? 'FF' : '00'
  name = name === 'white' ? 'black' : name
  const hex = nameToHex(name) + transparency 
  const rgba = hexAToRGBA(hex)
  return rgba
}