import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { TextField } from 'mui-rff';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const FormTaskToolMarker = ({
  name,
  type,
  fields,
  index,
  classes,
}) => {
  return (
    <Card className={classes.coordinates} variant="outlined">
      <Grid item container xs={12} spacing={3}>
      <input type='hidden' name={`${name}.easting`} />
      <input type='hidden' name={`${name}.northing`} />
      <input type='hidden' name={`${name}.shapetype`} />
      
      <h1>{`${type}`}</h1>
      <Grid item xs={5}>
        <TextField
          label="Easting"
          name={`${name}.easting4x4`}
          size="small"
          variant="outlined"
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </Grid>
      <Grid item xs={5}>
        <TextField
          label="Northing"
          name={`${name}.northing4x4`}
          size="small"
          variant="outlined"
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </Grid>
      <Grid item xs={2}>
      <IconButton
        onClick={() => fields.remove(index)}
        style={{ cursor: 'pointer', color: '#d23232' }}
        aria-label={`Remove Coordinates`}
        size="small"
      >
        <DeleteIcon />
      </IconButton>
      {/* <IconButton
        onClick={handleMapSelect}
        size="small"
      >
        <EditLocationIcon />
      </IconButton> */}
      </Grid>
      </Grid>
    </Card>
  )
};

export default FormTaskToolMarker;