import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { useAuth0Combined } from '../../hooks/useAuth0Combined';
import { useUserLocation } from '../../hooks/useUserLocation';
import { useQueryFactory } from '../../queries/query-factory';

// const useStyles = makeStyles((theme) => ({
// }));

function AltitudeChips(props) {
  const auth0 = useAuth0Combined();
  const { data: settings, isSuccess } = useQueryFactory('settings', auth0);
  const { measurements } = settings ? settings.data : {};
  const { currentAltitude } = useUserLocation();
  let altitudeValue = 0

  const formatNumber = (num, dec) => {
    return Number(num).toFixed(dec)
  }

  if (measurements === 'm') {
    altitudeValue = currentAltitude
  } 
  else if (measurements === 'ft') {
    altitudeValue = currentAltitude * 3.281
  }
  else if (measurements === 'km') {
    altitudeValue = currentAltitude / 1000
  }
  else if (measurements === 'mi') {
    altitudeValue = currentAltitude / 1609
  }

  if (!isSuccess) return '';
  return (
    <div>
      <Chip color="primary" size="small" label={`Alt: ${formatNumber(altitudeValue, 0)} ${measurements}`} />
      <br />
    </div>
  )
}

export default AltitudeChips 