
import * as L from 'leaflet';
import 'leaflet-gpx';
import { createLayerComponent } from '@react-leaflet/core';
import React from 'react';

// Suppress warnings about using useLayoutEffect on the server.
React.useLayoutEffect = React.useEffect;
const DEFAULT_TRACK_COLOR = '#FFFFFF';
const DEFAULT_MARKER_COLOR_BG = '#FFFFFF';
const DEFAULT_MARKER_COLOR_TEXT = '#000000';

const createLeafletElement = (
  {
    gpx,
    overlay
  },
  context
) => {
  const trackColor = overlay.trackColor ? overlay.trackColor : DEFAULT_TRACK_COLOR;
  const markerColorBG = overlay.markerColorBG ? overlay.markerColorBG : DEFAULT_MARKER_COLOR_BG;
  const markerColorText = overlay.markerColorText ? overlay.markerColorText : DEFAULT_MARKER_COLOR_TEXT;

  // add gpx filename or something to id layer
  const gpxTooltipClassname = `gpxWaypoint-tooltip`;
  const gpxWaypointClassname = `gpxWaypoint`;
  const stylesheetNode = document.createElement('style');
  stylesheetNode.appendChild(document.createTextNode(""));
  document.head.appendChild(stylesheetNode);
  stylesheetNode.textContent="";
  const stylesheet = stylesheetNode.sheet;
  stylesheet.insertRule(`.${gpxWaypointClassname} { width: 10px; height: 10px; background:${markerColorBG}; }`);
  stylesheet.insertRule(`.${gpxTooltipClassname} { background:${markerColorBG}; color: ${markerColorText} }`);

  const instance = new L.GPX(gpx, {
    async: true,
    polyline_options: {
      color: trackColor,
    },
    marker_options: {
      startIconUrl: '',
      endIconUrl: '',
      shadowUrl: '',
      wptIcons: {
        '': L.divIcon({
          iconSize: new L.Point(5, 5),
          tooltipAnchor: new L.Point(5, -5),
          className: gpxWaypointClassname,
        }),
      },
    }
  }).on('addpoint', (e) => {
    const latlng = e.point._latlng;
    const popupContents = `
      <div>
        <a class="popupMapLink" target="_blank" href="http://www.google.com/maps/place/${latlng.lat},${latlng.lng}">Google Maps</a>
        <br />
        <a class="popupMapLink" target="_blank" href="http://maps.apple.com/?daddr=${latlng.lat},${latlng.lng}">Apple Maps</a>
      </div>
    `;
    // const popupContents = '';
    // const popupContents = renderToStaticMarkup(
    //   <div>
    //     <Button
    //       variant="contained"
    //       target="_blank"
    //       href={`http://www.google.com/maps/place/${latlng.lat},${latlng.lng}`}
    //       size="large"
    //     >
    //       Google Maps
    //     </Button>
    //     <br />
    //     <br />
    //     <Button
    //       variant="contained"
    //       target="_blank"
    //       href={`http://maps.apple.com/?daddr=${latlng.lat},${latlng.lng}`}
    //       size="large"
    //     >
    //       Apple Maps
    //     </Button>
    //   </div>
    // );
    e.point.unbindPopup();
    e.point.bindPopup(popupContents);
    e.point.bindTooltip(
      e.point.options.title, 
      {
        permanent: true,
        direction: 'top',
        interactive: true,
        className: gpxTooltipClassname 
      })
  });

  return { instance, context };
}

const updateLeafletElement = () => {

}

const ReactLeafletGpx = createLayerComponent(createLeafletElement, updateLeafletElement);

export default ReactLeafletGpx;