import React, { useContext, useState } from 'react';

export const themes = {
  light: {
    foreground: '#000000',
    background: '#eeeeee',
  },
  dark: {
    foreground: '#ffffff',
    background: '#222222',
  },
  isTracking: false,
  userActiveTrack: null,
};

export const AppStatusContext = React.createContext({
  themes: themes.dark, // default value
});

export const useAppStatusContext = () => useContext(AppStatusContext);
export const AppStatusProvider = ({children}) => {
  const [ showLoading, setShowLoading ] = useState(null);
  const [ snackbarMessge, setSnackbarMessage ] = useState(false);

  // Tasksheets
  const [ activeTasksheets, setActiveTasksheets ] = useState();
  const processTasksheets = (tasksheets) => {
    let active = {};

    if (!tasksheets || tasksheets.data.length === 0) return;
    tasksheets.data.forEach(tasksheet => {
      if(tasksheet.doc.isActive) {
        active[tasksheet.id] = tasksheet;
      }
    });
    setActiveTasksheets(active)
  };

  const addActiveTasksheet = (tasksheet) => {
    setActiveTasksheets({
      ...activeTasksheets,
      [tasksheet.id]: tasksheet
    });
  };
  const removeActiveTasksheet = (tasksheet) => {
    if (!activeTasksheets[tasksheet.id]) return;

    delete activeTasksheets[tasksheet.id];
    setActiveTasksheets({
      ...activeTasksheets
    });
  };

   // Overlays
   const [ activeOverlays, setActiveOverlays ] = useState();
   const processOverlays = (overlays) => {
     let active = {};
 
     if (!overlays || overlays.data.length === 0) return;
     overlays.data.forEach(overlay => {
       if(overlay.doc.isActive) {
         active[overlay.id] = overlay;
       }
     });
     setActiveOverlays(active)
   };
 
   const addActiveOverlay = (overlay) => {
     setActiveOverlays({
       ...activeOverlays,
       [overlay.id]: overlay
     });
   };

  const removeActiveOverlay = (overlay) => {
    if (!activeOverlays[overlay.id]) return;
    delete activeOverlays[overlay.id];
    setActiveOverlays({
      ...activeOverlays
    });
  };

  // Flight Tracking
  const [ isTracking, setIsTracking ] = useState(false);
  const [ userActiveTrack ] = useState(null);
  const [ userActiveTrackNodes, setUserActiveTrackNodes ] = useState(null);

  const addActiveTrackNodes = (position) => {
    const positionObject = {
      timestamp: position.timestamp,
      coords: {
        accuracy: position.accuracy,
        altitude: position.altitude,
        altitudeAccuracy: position.altitudeAccuracy,
        heading: position.heading,
        latitude: position.latitude,
        longitude: position.longitude,
        speed: position.speed
      }
    }
    setUserActiveTrackNodes(
      [
        ...userActiveTrackNodes,
        positionObject
      ]
    );
  }

  const trackCreate = () => {
    console.error('track create, NONFUNCTIONAL')
  }

  return (
    <AppStatusContext.Provider
      value={{
        showLoading,
        setShowLoading,
        snackbarMessge,
        setSnackbarMessage,
        isTracking,
        setIsTracking,
        userActiveTrack,
        addActiveTrackNodes,
        trackCreate,

        activeTasksheets,
        processTasksheets,
        addActiveTasksheet,
        removeActiveTasksheet,

        activeOverlays,
        processOverlays,
        addActiveOverlay,
        removeActiveOverlay
      }}
    >
      {children}
    </AppStatusContext.Provider>
  )
};