import React from 'react';
import { TaskMarker } from '../components/Marker';
import { MapTaskToolMarker } from '../components/MapTaskTools/MapTaskToolMarker';
import { utmToLatLng } from '../utils/utm';
import { MapTaskToolPolygon } from './MapTaskTools/MapTaskToolPolygon';
import { MapTaskToolPolyline } from './MapTaskTools/MapTaskToolPolyline';

const MapToolComponents = {
  marker: MapTaskToolMarker,
  polygon: MapTaskToolPolygon,
  polyline: MapTaskToolPolyline,
};
/**
 * 
 * @param {tasksheets} tasksheets
 * @returns markers
 */
const ActiveTasksheets = ({tasksheets}) => {
  if (!tasksheets) return '';
  const taskAlphaPrefixes = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

  const markers = []
  Object.entries(tasksheets).forEach(([key, tasksheet]) => {
    // console.log('found tasksheet')
    // console.log(tasksheet)
    if (!tasksheet.doc.tasks) return
    tasksheet.doc.tasks.forEach((task) => {
      // console.log('Task =>')
      // console.log(task)
      if (!task || !task.tools) { return }
      task.tools.forEach((tool, index) => {
        if (!tool && tool.points.length === 0) return;
        // console.log(tool)
        const MapToolComponent = MapToolComponents[tool.shapetype];
        if (!MapToolComponent) return;
        const key = tasksheet.doc.name + task.name + task.number + task.color + task.markerDrop + task.tools.length + tool.shapetype + tool.points.length;
        markers.push(
          <MapToolComponent
            key={key}
            mapToolKey={key}
            task={task}
            tool={tool}
          />
        );
        let coord = {};
        return;

        const { easting, northing, zone, band } = coord
        if (!easting || !northing || !zone || !band) return

        // console.log(coord)
        const { lat, lng } = utmToLatLng(coord);

        if (!lat || !lng) return

        // const key = tasksheet.doc.name + task.name + task.number + task.color + zone + easting + northing;
        const labelPrefix = task.coords.length > 1 ? ` - ${taskAlphaPrefixes[index]}` : '';
        const label = 
          task.number || task.name ? 
            `${task.number ? task.number + ':' : ' '}${task.name ? task.name : ''}${labelPrefix}` 
            : `TASK${labelPrefix}`
        markers.push(
          <TaskMarker
            key={key}
            lat={lat}
            lng={lng}
            color={task.color}
            label={label}
            notes={task.notes}
            markerDrop={task.markerDrop}
          />
        )
      })
    })
  })
  return markers
};

export default ActiveTasksheets;