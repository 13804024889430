import {
  useQuery,
  useMutation,
} from "react-query";
import api from '../utils/api'

export const useQueryJsonDataFactory = (name, auth0) => useQuery(
  name,
  () => fetchData(name, auth0), {
    enabled: !!(!auth0.isLoading && auth0.token)
  }
);
export const useMutateJsonDataFactory = (name, auth0, queryClient) => useMutation(
  // (data) => updateData(name, auth0, data), 
  ({action, data}) => {
    let response;
    switch (action) {
      case 'update':
        response = updateData(name, auth0, data);
        break;
      case 'create':
        response = createData(name, auth0, data);
        break;
      case 'delete':
        response = deleteData(name, auth0, data);
        break;
      default:
    }
    return response;
  }, 
  mutateSideEffects(name, queryClient)
);

const fetchData = async (name, auth0) => {
  return await api.get(
    `/${name}`,
    {
      headers: {
        Authorization: `Bearer ${auth0.token}`
      }
    }
  );
}

const updateData = async (name, auth0, data) => {
  // console.log(`updating ${name}...`)
  // console.log(data)
  return await api.put(
  `/${name}/update`,
  {
    action: 'update',
    id: data.id,
    doc: data.doc
  }, {
  headers: {
    Authorization: `Bearer ${auth0.token}`
  }
  }
);
}

const createData = async (name, auth0, data) => {
  return await api.post(
  `/${name}/create`,
  {
    action: 'create',
    id: data.id,
    doc: data.doc
  }, {
  headers: {
    Authorization: `Bearer ${auth0.token}`
  }
  }
);
}

const deleteData = async (name, auth0, data) => {
  console.log(`deleting ${name} ${data.id}...`)
  console.log(auth0.token)
  return await api.delete(
  `/${name}/delete`, {
    headers: {
      Authorization: `Bearer ${auth0.token}`
    },
    data: {
      action: 'delete',
      id: data.id
    },
  }
);
}

const mutateSideEffects = (name, queryClient) => { 
  return {
    // Before mutate
    onMutate: async ({data: newData}, ...rest) => {

    },
    // Error!
    onError: () => {
      console.error(`Error updating ${name}`)
    },
    // After successful return
    onSuccess: async (newData, variables) => {
      console.log(`Success updating ${name}`)
      // console.log(data)
      // console.log(variables)
      // console.log(newData)

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(name);

      // Snapshot the previous value
      // Could probably remove the storing of the previous value because we always want the new
      // value to eventually go through.
      // const previousData = queryClient.getQueryData(name);

      // Optimistically update to the new value
      queryClient.setQueryData(name, old => {
        // console.log('optimisic updating...')
        // console.log(old)
        // console.log(newData)
        if (variables.action === 'update') {
          const itemIndex = old.data.findIndex((entry) => entry.id === newData.data.id);
          // console.log(`updating itemIndex => ${itemIndex}`)
          // console.log(old.data[itemIndex].doc.name)
          if (itemIndex > -1) {
            old.data[itemIndex] = newData.data;
          }
        }
        else if (variables.action === 'delete') {
          const itemIndex = old.data.findIndex((entry) => entry.id === variables.data.id);
          // console.log(`deleting itemIndex => ${itemIndex}`)
          if (itemIndex > -1) {
            old.data.splice(itemIndex, 1);
          }
        }
        // create
        else {
          old.data.push(newData.data);
        }
        // console.log('OLD =>')
        // console.log(old)
        return ({
          ...old,
        })
      }
      );

      // Return a context object with the snapshotted value
      // return { previousData };
    },
    // After everything
    onSettled: () => {},
  }
};