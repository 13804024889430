import React from 'react'
import OverlayForm from '../components/OverlayForm'
import {
  useParams
} from "react-router-dom";

const OverlayEdit = (props) => {
  const { id } = useParams()
  return (
    <React.Fragment>
      <OverlayForm 
        id={id}
      />
    </React.Fragment>
  )
}

export default OverlayEdit 