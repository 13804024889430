import React, { useRef } from 'react'
import clsx from 'clsx';
import { Popup, Tooltip, CircleMarker, Circle } from 'react-leaflet'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    outline: (props) => `2px solid ${props.color}`,
    zIndex: 9999
  },
  tooltipLogger: {
    outlineStyle: 'dashed !important'
  },
  taskMarker: {
    zIndex: 9999
  },
  taskMarkerIcon: {
    borderRadius: 20,
    background: 'blue',
    border: '1px solid black'
  }
})); 

export const TaskMarker = ({markerDrop, rings, ...props}) => {
  const parentMarkerRef = useRef();
  const color = props.color ? props.color : '#000';
  const isLogger = markerDrop && (markerDrop.includes('logger') || markerDrop.includes('electronic'));
  const classes = useStyles({...props, color});
  const tooltipClasses = clsx(isLogger && classes.tooltipLogger, classes.tooltip)
  const position = {
    lat: Number(props.lat),
    lng: Number(props.lng)
  };
  const popupContents = (
    <div>
      <h5>Directions:</h5>
      <a className="popupMapLink" target="_blank" rel="noopener noreferrer" href={`http://www.google.com/maps/place/${props.lat},${props.lng}`}>Google Maps</a>
      <br />
      <a className="popupMapLink" target="_blank" rel="noopener noreferrer" href={`http://maps.apple.com/?daddr=${props.lat},${props.lng}`}>Apple Maps</a>
    </div>
  );

  const handleInternalClick = (e) => {
    parentMarkerRef.current.togglePopup();
  };

  return (
    <CircleMarker
      ref={parentMarkerRef}
      className={classes.taskMarker}
      center={position}
      radius={1}
      color={color}
      weight={2}
      onClick={(e)=>{console.log(e)}}
      dashArray={isLogger ? '5,4' : null}
    >
      <Circle
        center={position}
        radius={100}
        color={color}
        weight={2}
        eventHandlers={{
          click: handleInternalClick
        }}
      />
      {rings.map((ringRadius) => {
        if (isNaN(ringRadius)) return;
        const key = `${props.lat}${props.lng}${ringRadius}ring`;
        return (
          <Circle
            key={key}
            center={position}
            radius={ringRadius * 1000}
            color={color}
            weight={3}
            fill={false}
          />
        );
      })}
      <Tooltip className={tooltipClasses} direction="top" offset={[0,-10]} opacity={1} permanent>
        {props.label}
      </Tooltip>
      <Popup>
        {props.notes}
        {popupContents}
      </Popup>
    </CircleMarker>
  )
}

export const TeamMarker = (props) => {
  const classes = useStyles(props);
  const position = {
    lat: Number(props.lat),
    lng: Number(props.lng)
  }

  return (
    <CircleMarker
      center={position}
      radius={10}
      color={'#f00'}
      // fillOpacity={'.8'}
      weight={1.5}
      onClick={(e)=>{console.log(e)}}
    >
      <Tooltip className={classes.tooltip} direction="top" offset={[0,150]} opacity={1} permanent>
        {props.label}
      </Tooltip>
      <Popup>
        {props.notes}
      </Popup>
    </CircleMarker>
  )
}

// export const PilotMarker = (props) => {
//   // const classes = useStyles();

//   const position = {
//     lat: Number(props.lat),
//     lng: Number(props.lng)
//   }
//   // const icon = window.google.maps ? window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW : ''
//   return (
//     <Marker
//       position={position}
//       // onClick={(e)=>{console.log(e)}}
//       // icon={{
//       //   path: icon,
//       //   scale: 3.5,
//       //   fillColor: '#00ff00',
//       //   strokeColor: '#00ff00'
//       // }}
//     >
//       <Tooltip direction="top" offset={[0,0]} opacity={1} permanent>
//         pilot
//       </Tooltip>
//     </Marker>
//   )
// }
