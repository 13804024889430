import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import { createControlComponent } from '@react-leaflet/core';
import 'leaflet.locatecontrol';


const createLeafletElement = (props) => {
  const instance = new L.Control.Locate(props);
  // TODO: How to get this started automatically
  // if (props.startDirectly) {
  //   setTimeout(() => {
  //     instance.start()
  //   }, 5000)
  // }
  // debugger
  return instance;
};


const LocateControlInstance = createControlComponent(createLeafletElement);
const LocateControl = (props) => {
  const locateControlRef = useRef();
  useEffect(() => {
      // https://stackoverflow.com/questions/67671931/updating-props-when-using-createcontrolcomponent-in-react-leaflet-3
      locateControlRef.current.start();
      setTimeout(() => {
        try {
          locateControlRef.current.stopFollowing();
        }
        catch (err) {
          console.log('Did not stop location following')
        }
      },2000)
  }, []);
  return (
    <LocateControlInstance 
      ref={locateControlRef}
      {...props}
    />
  )
}
// const LocateControl = (props) => {
//   const loadEvent = useMapEvent('load', () => {
//     console.log('loaded')
//   });
//   const moveEvent = useMapEvent('moveend', () => {
//     console.log('moved')
//   });

//   return <LocateControlFactory {...props} loadEvent={loadEvent} />;
// }

export default LocateControl;