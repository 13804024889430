import React, { useState } from 'react';
import { useAuth0Combined } from '../hooks/useAuth0Combined';
import { useQueryJsonDataFactory } from '../queries/query-jsonData-factory';

import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';

import Grid from '@material-ui/core/Grid';
import { TextField, Switches } from 'mui-rff'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Form } from 'react-final-form'

import AutoSave from './AutoSave'

const useStyles = makeStyles((theme) => ({
  buttons: {
    position: 'fixed',
    top: 0,
    left: 80,
    '& .MuiButton-contained.Mui-disabled': {
      backgroundColor: 'rgb(255, 255, 255)'
    }
  },
  teamsRoot: {
    width: 400,
  }
}));

let TeamsForm = (props) => {
  const classes = useStyles();
  const auth0 = useAuth0Combined();
  // eslint-disable-next-line no-unused-vars
  const {data: teams } = useQueryJsonDataFactory('teams', auth0);
  const [ snackbarMessage, setSnackbarMessage ] = useState('')
  const snackbarOpen = snackbarMessage ? true : false

  const save = async (values) => {
    if (!auth0.user) return
    props.teamsUpdate(auth0, values)
    setSnackbarMessage('Saved.')
  }

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarMessage('')
  }

  if (!props.teams && !props.teams.loaded) return ''
  const initialValues = {
    teamsDisplayName: props.teams.teamsDisplayName,
    teamsActive: props.teams.teamsActive,
    teamsChannelName: props.teams.teamsChannelName,
    teamsChannelPassword: props.teams.teamsChannelPassword,
  }
  return (
    <div className={classes.teamsRoot}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={5000}
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <Form
      onSubmit={save}
      initialValues={initialValues}
      mutators={{
      }}
      render={({ 
        form: {
          mutators: { push, pop }
        },
        form,
        submitting,
        pristine,
        values
      }) => (
        <form onSubmit={save}>
          <AutoSave debounce={1000} save={save} />
          <Grid className={classes.tasksheet} container spacing={3}>
            
            <Grid item xs={8}>
              <TextField 
                label="Display Name" 
                name="teamsDisplayName"
                required={true}
                variant="outlined"
                helperText="Name shown on map"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={<Switches
                  name="teamsActive"
                  data={{value: true}}
                />}
                label="Active"
                labelPlacement="top"
              />
            </Grid> 
            <Grid item xs={12}>
              <TextField 
                label="Channel Name" 
                name="teamsChannelName"
                required={true}
                variant="outlined"
                helperText="Shared team channel name"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                label="Channel Password" 
                name="teamsChannelPassword"
                variant="outlined"
                helperText="Optional password to prevent non-team from joining"
              />
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
        </form>
      )}
    />
    </div>
  )
};

export default TeamsForm;
