import React, { useState } from "react";
import { useAuth0Combined } from "../hooks/useAuth0Combined";
import { useQueryClient } from "react-query";
import { useQueryFactory, useMutateFactory } from "../queries/query-factory";
import { useMap } from "react-leaflet";
import { latLngToUtm } from "../utils/utm";
import { getMagneticNorthDeclination } from "../utils/mapping";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

import Grid from "@material-ui/core/Grid";
import "date-fns";
import InputLabel from "@material-ui/core/InputLabel";
import { TextField, Select } from "mui-rff";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import { Button, MenuItem } from "@material-ui/core";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";

import AutoSave from "./AutoSave";

const useStyles = makeStyles((theme) => ({
  buttons: {
    position: "fixed",
    top: 0,
    left: 80,
    "& .MuiButton-contained.Mui-disabled": {
      backgroundColor: "rgb(255, 255, 255)",
    },
  },
  tasksheetRoot: {
    width: 400,
  },
  tasksheet: {
    marginTop: 0,
  },
  task: {
    "& > *": {
      padding: theme.spacing(1),
    },
  },
  coordinates: {
    backgroundColor: "rgba(200,200,200,.7)",
    "& > *": {
      padding: theme.spacing(1),
    },
  },
}));

let SettingsForm = (props) => {
  const auth0 = useAuth0Combined();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [operatingLocation, setOperatingLocation] = useState();
  const [declination, setDeclination] = useState();
  const snackbarOpen = snackbarMessage ? true : false;
  const { data: settings, isError, isSuccess } = useQueryFactory(
    "settings",
    auth0
  );
  const settingsMutate = useMutateFactory("settings", auth0, queryClient);
  const map = useMap();

  const save = async (values) => {
    // if (!auto0.user) return
    // const token = await getTokenSilently()
    // console.log(values)
    settingsMutate.mutate({ action: "update", data: values });
    setSnackbarMessage("Saved.");
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage("");
  };

  const handleMapSelect = (e) => {
    console.log("click");
    console.log(map.getCenter());
    const utmLocation = latLngToUtm(map.getCenter());
    console.log(utmLocation);
    setOperatingLocation(utmLocation);
  };

  const handleGetDeclination = async (e) => {
    const latLng = map.getCenter();
    const declination = await getMagneticNorthDeclination(
      latLng.lat,
      latLng.lng
    );

    setDeclination(declination);
  };

  if (!isSuccess && !isError) return "Loading settings...";
  if (isError) return "Error loading settings...";
  const initialValues = {
    measurements: settings.data.measurements,
    speed: settings.data.speed,
    coordinates: settings.data.coordinates,
    compass: settings.data.compass,
    operatingLocation: operatingLocation
      ? operatingLocation
      : settings.data.operatingLocation,
    declination: declination ? declination : settings.data.declination,
  };
  return (
    <div className={classes.tasksheetRoot}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        autoHideDuration={5000}
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <Form
        onSubmit={save}
        initialValues={initialValues}
        mutators={{
          ...arrayMutators,
        }}
        render={({
          form: {
            mutators: { push, pop },
          },
        }) => (
          <form onSubmit={save}>
            <AutoSave debounce={1000} save={save} />
            <Grid className={classes.tasksheet} container spacing={3}>
              <Grid item container xs={12} spacing={1} alignItems="flex-start">
                <Grid item xs={12}>
                  <InputLabel>Operating Area(70km)</InputLabel>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Zone"
                    name={`operatingLocation.zone`}
                    size="small"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    label="Band"
                    name={`operatingLocation.band`}
                    size="small"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Easting"
                    name={`operatingLocation.easting`}
                    size="small"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Northing"
                    name={`operatingLocation.northing`}
                    size="small"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleMapSelect}
                    startIcon={<EditLocationIcon />}
                  >
                    Set Map Center Location
                  </Button>
                </Grid>
              </Grid>

              <Grid item container xs={12}>
                <Grid item xs={6}>
                  <TextField
                    label="Magnetic Declination"
                    name="declination"
                    size="small"
                    variant="outlined"
                    InputProps={{}}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Button onClick={handleGetDeclination}>
                    Get Declination
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="speed"
                  label="Speed"
                  formControlProps={{ margin: "normal" }}
                >
                  <MenuItem value="kt">Knots</MenuItem>
                  <MenuItem value="kmh">Kilometers per Hour</MenuItem>
                  <MenuItem value="mph">Miles per Hour</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="measurements"
                  label="Measurements"
                  formControlProps={{ margin: "normal" }}
                >
                  <MenuItem value="m">Meters</MenuItem>
                  <MenuItem value="km">Kilometers</MenuItem>
                  <MenuItem value="mi">Miles</MenuItem>
                  <MenuItem value="ft">Feet</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="coordinates"
                  label="Coordinates Format"
                  formControlProps={{ margin: "normal" }}
                >
                  <MenuItem value="utm">UTM</MenuItem>
                  <MenuItem value="latlng">Latitude/Longitude</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <Select
                  name="compass"
                  label="Compass"
                  formControlProps={{ margin: "normal" }}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="magnetic">Magnetic</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
          </form>
        )}
      />
    </div>
  );
};

export default SettingsForm;
