import React from 'react'
import TasksheetForm from '../components/TasksheetForm'
import {
  useParams
} from "react-router-dom";

const TasksheetEdit = (props) => {
  const { id } = useParams()
  return (
    <React.Fragment>
      <TasksheetForm 
        id={id}
      />
    </React.Fragment>
  )
}

export default TasksheetEdit 