import { useUserLocation } from '../hooks/useUserLocation';
import { useMapEvents } from 'react-leaflet';

const MapEvents = () => {
  const { updateUserLocation } = useUserLocation();
  useMapEvents({
    locationfound: (e) => {
      updateUserLocation(e);
    },
    click: () => {
      console.log('map click')
    }
  });

  return '';
}

export default MapEvents;