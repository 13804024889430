import React from "react";
// import { makeStyles } from '@material-ui/core/styles';
import Chip from "@material-ui/core/Chip";
import { useUserLocation } from "../../hooks/useUserLocation";
import { useQueryFactory } from "../../queries/query-factory";
import { useAuth0Combined } from "../../hooks/useAuth0Combined";
import { formatNumber } from "../../utils/numberUtil";

// const useStyles = makeStyles((theme) => ({
// }));

function SpeedChips(props) {
  // const classes = useStyles();
  const {
    userCurrentLocation,
    currentSpeed,
    currentHeading,
  } = useUserLocation();
  const auth0 = useAuth0Combined();
  const { data: settings, isSuccess } = useQueryFactory("settings", auth0);
  const { speed: speedSetting, compass, declination } = settings
    ? settings.data
    : {};
  let speedValue = 0,
    headingValue = 0,
    headingLabel = "";

  if (compass === "true") {
    headingValue = currentHeading;
    headingLabel = "TC";
  } else if (compass === "magnetic") {
    // headingValue = heading
    headingValue = wrap360(currentHeading + declination);
    headingLabel = "MC";
  }

  if (speedSetting === "kmh") {
    speedValue = currentSpeed * 3.6;
  } else if (speedSetting === "mph") {
    speedValue = currentSpeed * 2.237;
  } else if (speedSetting === "kt") {
    speedValue = currentSpeed * 1.944;
  }

  if (!isSuccess) return "";
  return (
    <div>
      <Chip
        color="primary"
        size="small"
        label={`Hdg: ${formatNumber(headingValue, 0)} ${headingLabel}`}
      />
      <br />
      <Chip
        color="primary"
        size="small"
        label={`Spd: ${formatNumber(speedValue, 0)} ${speedSetting}`}
      />
      <br />
    </div>
  );
}

export default SpeedChips;

/**
 * Constrain degrees to range 0..360 (e.g. for bearings); -1 => 359, 361 => 1.
 *
 * @private
 * @param {number} degrees
 * @returns degrees within range 0..360.
 */
const wrap360 = (degrees) => {
  if (0 <= degrees && degrees < 360) return degrees; // avoid rounding due to arithmetic ops if within range
  return ((degrees % 360) + 360) % 360; // sawtooth wave p:360, a:360
};
