import {
  useQuery,
  useMutation,
} from "react-query";
import api from '../utils/api'

export const useQueryFactory = (name, auth0) => useQuery(
  name,
  () => fetchData(name, auth0), {
    enabled: !!(!auth0.isLoading && auth0.token)
  }
);
export const useMutateFactory = (name, auth0, queryClient) => useMutation(
  // (data) => updateData(name, auth0, data), 
  ({action, data}) => {
    switch (action) {
      case 'update':
        updateData(name, auth0, data);
        break;
      case 'create':
        createData(name, auth0, data);
        break;
      case 'delete':
        deleteData(name, auth0, data);
        break;

      default:
    }
  }, 
  mutateSideEffects(name, queryClient)
);

const fetchData = async (name, auth0) => {
  return api.get(
  `/${name}`,
  {
    headers: {
      Authorization: `Bearer ${auth0.token}`
    }
  }
);
}

const updateData = (name, auth0, data) => {
  api.put(
  `/${name}/update`,
  {
    [name]: data
  }, {
  headers: {
    Authorization: `Bearer ${auth0.token}`
  }
  }
);
}

const createData = (name, auth0, data) => {
  api.post(
  `/${name}/create`,
  {
    [name]: data
  }, {
  headers: {
    Authorization: `Bearer ${auth0.token}`
  }
  }
);
}

const deleteData = (name, auth0, data) => {
  api.delete(
  `/${name}/delete`,
  {
    [name]: data
  }, {
  headers: {
    Authorization: `Bearer ${auth0.token}`
  }
  }
);
}

const mutateSideEffects = (name, queryClient) => { 
  return {
    // Before mutate
    onMutate: async ({data: newData}) => {
      // TODO: check action and if it is a delete then we need to DELETE optimistically
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(name);

      // Snapshot the previous value
      // Could probably remove the storing of the previous value because we always want the new
      // value to eventually go through.
      const previousData = queryClient.getQueryData(name);
      console.log(newData)

      // Optimistically update to the new value
      queryClient.setQueryData(name, old => {
        console.log(old)
        return ({
          ...old,
          data: newData
        })
      }
      );

      // Return a context object with the snapshotted value
      return { previousData };
    },
    // Error!
    onError: () => {
      console.error(`Error updating ${name}`)
    },
    // After successful return
    onSuccess: (data, variables) => {
      console.log(`Success updating ${name}`)
      // console.log(data)
      console.log(variables)
      // this isnt the right param names currently
      // queryClient.setQueryData(['settings', { id: variables.id }], data)
    },
    // After everything
    onSettled: () => {},
  }
};