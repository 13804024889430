import React, { useState, useEffect, useRef, useMemo } from "react";
import L from "leaflet";
import { Marker, Polyline, Tooltip, Pane, useMap } from "react-leaflet";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import StraightenIcon from "@material-ui/icons/Straighten";
import { useQueryFactory } from "../queries/query-factory";
import { useAuth0Combined } from "../hooks/useAuth0Combined";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  hideRuler: {
    display: "none",
    visibility: "hidden",
  },
  rulerControl: {
    width: 30,
    height: 30,
    background: "white",
  },
  rulerControlButton: {
    // position: 'absolute',
    // top: 10,
    // right: 100,
    // zIndex: 1000,
    // border: '2px solid black',
    padding: "3px",
    minWidth: "auto",
    // borderRadius: '10px'
  },
  rulerControlActive: {
    background: "lightgreen",
    border: "2px solid green !important",
  },
  rulerPane: {
    zIndex: 9999,
  },
  rulerDialog: {
    width: "40vw",
    left: "27vw",
    padding: "5px",
    position: "absolute",
    top: "35px",
    background: "#FFF",
    border: "2px solid #000",
    zIndex: 9999,
  },
}));

const formatNumber = (num, dec) => {
  return Number(num).toFixed(dec);
};

const Ruler = () => {
  const classes = useStyles();
  const auth0 = useAuth0Combined();
  const { data: settings } = useQueryFactory("settings", auth0);
  const { measurements, compass, declination } = settings ? settings.data : {};
  const map = useMap();

  const controlRef = useRef();
  const m1Ref = useRef();
  const m2Ref = useRef();
  const [m1position, setM1Position] = useState(
    new L.LatLng(35.224587, -106.697946)
  );
  const [m2position, setM2Position] = useState(
    new L.LatLng(35.235697, -106.709056)
  );
  const polylinePositions = [m1position, m2position];

  const [rulerOpen, setRulerOpen] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    L.DomEvent.disableClickPropagation(controlRef.current);
    // eslint-disable-next-line no-undef
    L.DomEvent.disableScrollPropagation(controlRef.current);
  });

  const paneName = "reactLeafletRuler";
  let distance = "";
  let bearing = "";
  let backBearing = "";
  let bearingLabel = "";

  if (m1position && m2position) {
    // Distance will be in meters by default
    distance = getDistance(m1position, m2position);
    if (measurements === "ft") {
      distance = formatNumber(distance * 3.281, 2);
    } else if (measurements === "km") {
      distance = formatNumber(distance / 1000, 2);
    } else if (measurements === "mi") {
      distance = formatNumber(distance / 1609, 2);
    }
    if (compass === "true") {
      bearingLabel = "TC";
      bearing = wrap360(formatNumber(getBearing(m1position, m2position), 0));
      backBearing = wrap360(
        formatNumber(getBearing(m2position, m1position), 0)
      );
    } else if (compass === "magnetic") {
      bearingLabel = "MC";
      bearing = wrap360(
        formatNumber(
          Number(getBearing(m1position, m2position)) - Number(declination),
          0
        )
      );
      backBearing = wrap360(
        formatNumber(
          Number(getBearing(m2position, m1position)) - Number(declination),
          0
        )
      );
    }
  }

  const handleControlClick = (e) => {
    const bounds = map.getBounds();
    // toggleDialog()
    setM1Position(bounds.getCenter());
    setM2Position(bounds.pad(-0.4).getSouthEast());
    setRulerOpen(!rulerOpen);
  };

  return (
    <React.Fragment>
      {/* <Control position={'topright'} >
       <button onClick={handleControlClick}><StraightenIcon size="small" /></button>
     </Control> */}
      <div className="leaflet-bottom leaflet-right">
        <div
          className={clsx(
            "leaflet-control",
            "leaflet-bar",
            classes.rulerControl,
            rulerOpen && `${classes.rulerControlActive}`
          )}
        >
          <Button
            variant="contained"
            ref={controlRef}
            className={classes.rulerControlButton}
            onClick={handleControlClick}
          >
            <StraightenIcon size="small" />
          </Button>
        </div>
      </div>
      <div
        className={clsx(classes.rulerDialog, {
          [classes.hideRuler]: !rulerOpen,
        })}
      >
        Distance: {distance} {measurements}
        <br />
        Bearing: {Math.abs(bearing)} / {Math.abs(backBearing)} {bearingLabel}
      </div>
      <Pane
        key={`rulerVisible-${rulerOpen}`}
        name={paneName}
        className={clsx(classes.rulerPane, { [classes.hideRuler]: !rulerOpen })}
      >
        {/* <Dialog 
         ref={(ref) => { dialogRef = ref; }}
         anchor={dialogAnchor}
         size={[350,40]}
         minSize={[250,50]}
         initOpen={false}
       >
         <React.Fragment>Ruler</React.Fragment>
       </Dialog> */}

        <RulerMarker
          ref={m1Ref}
          position={m1position}
          eventHandlers={useMemo(
            () => ({
              drag(e) {
                const marker = m1Ref.current;
                if (marker != null) {
                  setM1Position(marker._latlng);
                }
              },
              mouseup(e) {
                const marker = m1Ref.current;
                if (marker != null) {
                  setM1Position(e.latlng);
                }
              },
            }),
            []
          )}
          styles={{
            fillColor: "#0f0",
            color: "#0f0",
          }}
        >
          {/* <Tooltip direction="top" offset={[0,-31]} opacity={1} permanent pane={paneName}>
           Distance: {distance} {measurements} 
           <br />
           Bearing: {Math.abs(bearing)} / {Math.abs(backBearing)} {bearingLabel}
         </Tooltip> */}
        </RulerMarker>
        <Polyline positions={polylinePositions} color="#f00" />
        <RulerMarker
          ref={m2Ref}
          name="m2"
          position={m2position}
          eventHandlers={useMemo(
            () => ({
              drag(e) {
                const marker = m2Ref.current;
                if (marker != null) {
                  setM2Position(marker._latlng);
                }
              },
              mouseup(e) {
                const marker = m2Ref.current;
                if (marker != null) {
                  setM2Position(e.latlng);
                }
              },
            }),
            []
          )}
        >
          {/* <Tooltip direction="bottom" offset={[0,31]} opacity={1} permanent pane={paneName}>
           Distance: {distance} {measurements} 
           <br />
           Bearing: {Math.abs(backBearing)} / {Math.abs(bearing)} {bearingLabel}
         </Tooltip> */}
        </RulerMarker>
      </Pane>
    </React.Fragment>
  );
};

export default Ruler;

const getDistance = (from, to) => {
  const distance = from.distanceTo(to).toFixed(0);
  return distance;
};

//http://www.movable-type.co.uk/scripts/latlong.html
const getBearing = function (from, /*LatLng*/ to) /*->Double*/ {
  // const R = 6378137, // earth radius in meters
  const d2r = Math.PI / 180, // Degrees to Radian
    r2d = 180 / Math.PI, // Radian to degrees
    // dLat = (to.lat - from.lat) * d2r,
    dLon = (to.lng - from.lng) * d2r,
    lat1 = from.lat * d2r,
    lat2 = to.lat * d2r,
    x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon),
    y = Math.sin(dLon) * Math.cos(lat2),
    bearing = wrap360(Math.atan2(y, x) * r2d);

  return Number(bearing).toFixed(0);
};

/**
 * Constrain degrees to range 0..360 (e.g. for bearings); -1 => 359, 361 => 1.
 *
 * @private
 * @param {number} degrees
 * @returns degrees within range 0..360.
 */
const wrap360 = (degrees) => {
  if (0 <= degrees && degrees < 360) return degrees; // avoid rounding due to arithmetic ops if within range
  return ((degrees % 360) + 360) % 360; // sawtooth wave p:360, a:360
};

/**
 * Modified from https://github.com/domoritz/leaflet-locatecontrol/blob/gh-pages/src/L.Control.Locate.js
 * @param {*} props
 */
const RulerMarker = React.forwardRef((props, ref) => {
  const [icon, setIcon] = useState(null);

  const createIcon = function (options) {
    let style = "";

    if (options.color !== undefined) {
      style += "stroke:" + options.color + ";";
    }
    if (options.weight !== undefined) {
      style += "stroke-width:" + options.weight + ";";
    }
    if (options.fillColor !== undefined) {
      style += "fill:" + options.fillColor + ";";
    }
    if (options.fillOpacity !== undefined) {
      style += "fill-opacity:" + options.fillOpacity + ";";
    }
    if (options.opacity !== undefined) {
      style += "opacity:" + options.opacity + ";";
    }

    const iconSVG = getIconSVG(options, style);

    const icon = L.divIcon({
      className: iconSVG.className,
      html: iconSVG.svg,
      iconSize: [iconSVG.w, iconSVG.h],
    });

    return icon;
  };

  useEffect(() => {
    /** Inner marker style properties. Only works if your marker class supports `setStyle`. */
    const markerStyle = {
      className: "leaflet-ruler-marker",
      color: "#f00",
      fillColor: "#f00",
      fillOpacity: 0.3,
      weight: 3,
      opacity: 0.7,
      radius: 30,
    };
    const customStyles = props.styles ? props.styles : {};
    const styles = { ...markerStyle, ...customStyles };
    setIcon(createIcon(styles));
  }, []);

  /**
   * Return the raw svg for the shape
   *
   * Split so can be easily overridden
   */
  const getIconSVG = function (options, style) {
    var r = options.radius;
    var w = options.weight;
    var s = r + w;
    var s2 = s * 2;
    var svg =
      '<svg xmlns="http://www.w3.org/2000/svg" width="' +
      s2 +
      '" height="' +
      s2 +
      '" version="1.1" viewBox="-' +
      s +
      " -" +
      s +
      " " +
      s2 +
      " " +
      s2 +
      '">' +
      '<circle r="' +
      r +
      '" style="' +
      style +
      '" />' +
      "</svg>";
    return {
      className: "leaflet-ruler-marker-icon",
      svg: svg,
      w: s2,
      h: s2,
    };
  };

  // const markerIcon = createIcon(markerStyle)

  if (!icon) return "";

  return (
    <Marker
      ref={ref}
      icon={icon}
      // position={props.position}
      draggable={true}
      // ondragend={props.ondragend}
      {...props}
    ></Marker>
  );
});
