import React, { useState, useEffect, useRef } from "react";
import { FeatureGroup, useMap } from "react-leaflet";
// import Utm from 'geodesy/utm.js';
import { utmToLatLng } from "../utils/utm";
import {
  bearingsToPreferredCompass,
  measurementToPreferredUnits,
} from "../utils/mapping";
import { formatNumber } from "../utils/numberUtil";
import { nameToRGBA } from "../utils/colors";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemText from "@material-ui/core/ListItemText";
import ExploreIcon from "@material-ui/icons/Explore";
import Button from "@material-ui/core/Button";

import { useQueryFactory } from "../queries/query-factory";
import { useAuth0Combined } from "../hooks/useAuth0Combined";
import { useUserLocation } from "../hooks/useUserLocation";

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  hide: {
    display: "none",
  },
  inline: {
    display: "inline",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  task: {
    textAlign: "left",
  },
  taskLabel: {
    fontWeight: "bold",
  },
  taskValue: {
    fontSize: "1.2em",
  },
  tooltip: {
    outline: (props) => `2px solid ${props.color}`,
  },
  control: {
    right: 40,
  },
  controlButton: {
    width: 30,
    height: 30,
    // position: 'absolute',
    // top: 10,
    // right: 100,
    // zIndex: 1000,
    // border: '2px solid black',
    // padding: '3px',
    minWidth: "auto",
    // borderRadius: '10px'
  },
  controlActive: {
    backgroundColor: "lightgreen !important",
    outline: "2px solid green !important",
  },
}));

//https://leafletjs.com/reference-1.6.0.html#map-distance
let TasksheetDashboard = (props) => {
  const auth0 = useAuth0Combined();
  const { data: settings } = useQueryFactory("settings", auth0);
  const { measurements, compass, magDeclination } = settings
    ? settings.data
    : {};
  const { tasksheets } = props;
  const { userCurrentLocation } = useUserLocation();
  const classes = useStyles(props);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const map = useMap();

  const handleControlClick = () => {
    if (drawerOpen) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const renderTasks = () => {
    const tasksList = [];

    // console.log('dash tasksheets => ',tasksheets)
    if (!tasksheets) return;
    Object.entries(tasksheets).forEach(([key, tasksheet]) => {
      if (!tasksheet.doc.tasks) return;
      tasksheet.doc.tasks.forEach((task) => {
        if (!task || !task.coords) {
          return;
        }
        task.coords.forEach((coord) => {
          // console.log(coord)
          if (!coord) return;
          const { easting, northing, zone, band } = coord;
          if (!easting || !northing || !zone || !band) return;

          const { lat, lng } = utmToLatLng(coord);
          if (!lat || !lng) return;

          const key =
            userCurrentLocation.lat +
            userCurrentLocation.lng +
            tasksheet.doc.name +
            task.name +
            task.number +
            task.color +
            zone +
            easting +
            northing;
          const distance = measurementToPreferredUnits(
            formatNumber(map.distance(userCurrentLocation, { lat, lng }), 0),
            measurements
          );
          const bearings = bearingsToPreferredCompass(
            userCurrentLocation,
            { lat, lng },
            compass,
            magDeclination
          );
          // console.log(task)

          const locationListItem = coord ? (
            <ListItem dense divider>
              <ListItemText>
                {/* Coords: {userInfo.location.latlng.lat} {userInfo.location.latlng.lng} */}
                <span className={classes.taskValue}>
                  {zone}
                  {band} <strong>{easting}</strong> E{" "}
                  <strong>{northing}</strong> N
                </span>
              </ListItemText>
            </ListItem>
          ) : null;

          const bearingListItem =
            userCurrentLocation && lat && lng ? (
              <ListItem dense>
                <ListItemText>
                  <span className={classes.taskLabel}>Dist:</span>{" "}
                  <span className={classes.taskValue}>{distance}</span>
                </ListItemText>
                <ListItemText>
                  <span className={classes.taskLabel}>Bearing:</span>{" "}
                  <span className={classes.taskValue}>
                    {bearings.bearing}
                    {bearings.bearingLabel}
                  </span>
                </ListItemText>
              </ListItem>
            ) : null;

          const altitudeListItem = task.altitude ? (
            <ListItem dense>
              <ListItemText>
                <span className={classes.taskLabel}>Altitude:</span>{" "}
                <span className={classes.taskValue}>{task.altitude}</span>
              </ListItemText>
            </ListItem>
          ) : null;

          const dropTypeListItem =
            task.color || task.markerDrop ? (
              <ListItem dense>
                <ListItemText>
                  <span className={classes.taskLabel}>Color:</span>{" "}
                  <span className={classes.taskValue}>{task.color}</span>
                </ListItemText>
                <ListItemText>
                  <span className={classes.taskLabel}>Drop:</span>{" "}
                  <span className={classes.taskValue}>{task.markerDrop}</span>
                </ListItemText>
              </ListItem>
            ) : null;

          const timesListItem =
            task.openTime || task.closeTime ? (
              <ListItem dense>
                <ListItemText>
                  <span className={classes.taskLabel}>Open:</span>{" "}
                  <span className={classes.taskValue}>{task.openTime}</span>
                </ListItemText>
                <ListItemText>
                  <span className={classes.taskLabel}>Close:</span>{" "}
                  <span className={classes.taskValue}>{task.closeTime}</span>
                </ListItemText>
              </ListItem>
            ) : null;

          const notesListItem = task.notes ? (
            <ListItem dense>
              <ListItemText>{task.notes}</ListItemText>
            </ListItem>
          ) : null;

          const color = task.color ? nameToRGBA(task.color) : "";

          tasksList.push(
            // <Paper key={userInfo.socketId} variant="outlined">
            <Paper
              key={key}
              variant="outlined"
              style={{ boxShadow: `inset 0em 0em .5em .1em ${color}` }}
            >
              <List
                className={classes.task}
                dense
                disablePadding
                subheader={
                  <ListSubheader disableSticky color="primary">
                    {/* {userInfo.teamsDisplayName} */}
                    {task.name} #{task.number}
                  </ListSubheader>
                }
              >
                {locationListItem}
                {dropTypeListItem}
                {timesListItem}
                {bearingListItem}
                {altitudeListItem}
                {notesListItem}
              </List>
              <Divider />
            </Paper>
          );
        });
      });
    });
    return tasksList;
  };

  return (
    <FeatureGroup>
      <div className={clsx("leaflet-bottom leaflet-right", classes.control)}>
        <div className={clsx("leaflet-control", "leaflet-bar")}>
          <Button
            variant="contained"
            onClick={handleControlClick}
            className={clsx(
              classes.controlButton,
              drawerOpen && `${classes.controlActive}`
            )}
          >
            <ExploreIcon size="small" />
          </Button>
        </div>
      </div>
      <Drawer
        ModalProps={{
          container: document.querySelector("#root"),
          disablePortal: false,
        }}
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <h3>Tasks</h3>
        <List>{renderTasks()}</List>
      </Drawer>
    </FeatureGroup>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     // tasksheetsActive: state.tasksheetsActive,
//   }
// }

// export default connect(mapStateToProps, {
// })(TasksheetDashboard)

export default TasksheetDashboard;
