import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { FieldArray } from 'react-final-form-arrays'
import { TextField } from 'mui-rff';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddLocation from '@material-ui/icons/AddLocation';
import EditLocation from '@material-ui/icons/EditLocation';
import Typography from '@material-ui/core/Typography';
import { useMap } from 'react-leaflet';
import { convertLatLngTo4x4 } from '../../utils/utm';
import { taskAlphaPrefixes } from '../MapTaskTools/constants';

const FormTaskToolPolyline = ({
  name,
  type,
  fields,
  index,
  classes,
  form,
  push,
}) => {
  const map = useMap();

  // console.log(form)
  // console.log(fields)
  useEffect(() => {
    if (!fields.value[index].points || fields.value[index].points.length === 0) {
      addPoint();
    }
  }, []);

  const addPoint = () => {
    push(`${name}.points`, undefined);
  }

  return (
    <Card className={classes.coordinates} variant="outlined">
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <input type='hidden' name={`${name}.shapetype`} />

          <Grid item xs={2}>
            <IconButton
                onClick={() => fields.remove(index)}
                // onDoubleClick={() => fields.remove(index)}
                style={{ cursor: 'pointer', color: '#d23232' }}
                className={classes.toolIconsDelete}
                aria-label={`Remove Coordinates`}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="overline">
              {`${type}`}
            </Typography>
          </Grid>
          <FieldArray name={`${name}.points`}>
            {({ fields }) =>
              fields.map((name, index) => {
                const eastingFieldName = `${name}.easting4x4`;
                const northingFieldName = `${name}.northing4x4`;

                const setCoordsFromMap = () => {
                  console.log('setCoords')
                  const center4x4 = convertLatLngTo4x4(map.getCenter());
                  console.log(center4x4)
                  form.change(eastingFieldName, center4x4.easting);
                  form.change(northingFieldName, center4x4.northing);

                };
                const rowLabel = taskAlphaPrefixes[index];

                return (
                  <Grid key={`${name}`} style={{margin: "0 0 5px 0"}} container spacing={1}>

                    <input type='hidden' name={`${name}.easting`} />
                    <input type='hidden' name={`${name}.northing`} />
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => fields.remove(index)}
                        className={classes.toolIconsDelete}
                        aria-label={`Remove Coordinates`}
                        size="small"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="h6">
                        {rowLabel}:
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Easting"
                        name={eastingFieldName}
                        size="small"
                        variant="outlined"
                        inputMode="numeric"
                        type="tel"
                        pattern="[0-9]*"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Northing"
                        name={northingFieldName}
                        size="small"
                        variant="outlined"
                        inputMode="numeric"
                        type="tel"
                        pattern="[0-9]*"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <IconButton
                        onClick={setCoordsFromMap}
                        className={classes.toolIcons}
                        aria-label={`Set Marker Coordinates`}
                        size="small"
                      >
                        <EditLocation />
                      </IconButton>
                      <IconButton
                        onClick={addPoint}
                        className={classes.toolIcons}
                        aria-label={`Add New Coordinates`}
                        size="small"
                      >
                        <AddLocation />
                      </IconButton>
                    </Grid>
                  </Grid>
                );
            })
            }
          </FieldArray>
          
        </Grid>
      </Grid>
    </Card>
  )
};

export default FormTaskToolPolyline;