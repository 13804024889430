import axios from 'axios'
import JSZip from 'jszip'

const loadKml = async (url) => {
      const response = await axios.get(url, { responseType:'arraybuffer'})
      const file = response.data
      const string = await (isZipped(file) ? parseKMZ(file) : parseKML(file))
      let xml = toXML(httpToHttps(string));
      /**
       * `parseGroundOverlay` does not parse LatLonQuad correctly
       * https://github.com/windycom/leaflet-kml/blob/master/L.KML.js#L369
       * 
       * Remove the <GroundOverlay> for now
       */
      const latlonquadElement = xml.getElementsByTagName('gx:LatLonQuad')[0];
      latlonquadElement.parentElement.remove();

      // should we check for folders?
      // https://github.com/Raruto/leaflet-kmz/blob/master/src/KMZLoader.js#L69

      return xml;
}

const isZipped = (file) => {
  // console.log('isZipped...')
  var P = new Uint8Array(file, 0, 1); // offset, length
  var K = new Uint8Array(file, 1, 1);
  var PK = String.fromCharCode(P, K);
  return 'PK' === PK;
}

const httpToHttps = (text) => {
  return text.replace(/(http:)/g, "https:");
}

const toXML = (text) => {
  return (new DOMParser()).parseFromString(text, 'text/xml');
}

const parseKML = (file) => {
  return decodeKMLString(file)
}

const decodeKMLString = (data) => {
  let utf8decoder = new TextDecoder();
  return utf8decoder.decode(data)
  // return data instanceof ArrayBuffer ? String.fromCharCode.apply(null, new Uint8Array(data)) : data;
}

const parseKMZ = async (file) => {
  // console.log('parseKMZ')
  const unzipped = await JSZip.loadAsync(file)
  const kmlString = await unzipped.file('doc.kml').async('string')

  return kmlString
}

export default loadKml