import { useEffect, useState } from 'react';
import { useAuth0 } from "../react-auth0-spa";

export const useAuth0Combined = () => {
  const { 
    loading,
    user,
    isAuthenticated,
    getTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0()
  const [ token, setToken ] = useState(null);

  useEffect(() => {
    async function setTokenState() {
      try {
        const token = await getTokenSilently();
        console.log('setting token')
        setToken(token);
      }
      catch (err) {
        console.log('failed to set token')
      }
    }
    setTokenState();
  },[getTokenSilently])

  return {
    user,
    token,
    // isLoading: loading || !token,
    isLoading: loading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  }
}