import React from 'react'
import { Polyline } from 'react-leaflet'
import { utmToLatLng } from '../../utils/utm';

export const MapTaskToolPolyline = ({
  task,
  tool,
}) => {
  const pathColor = task.color ? task.color : 'black';
  let latLngPoints = [];
  let pathOptions = {
    color: pathColor,
    fill: false,
  };

  if (tool.points.length === 0) return;
  tool.points.forEach((point) => {
    if (!point || !point.easting || !point.northing) return;
    const { lat, lng }= utmToLatLng(point);
    latLngPoints.push([lat, lng]);
  });

  return <Polyline
    pathOptions={pathOptions}
    positions={latLngPoints}
  />;
}
