// import React, { useState } from 'react';
// import { useAuth0Combined } from '../hooks/useAuth0Combined';
// import { useQueryClient } from 'react-query';
// import { Redirect } from 'react-router-dom'
// import { useQueryJsonDataFactory, useMutateJsonDataFactory } from '../queries/query-jsonData-factory';
// import { nanoid } from 'nanoid/non-secure';

// import { Form, Field } from 'react-final-form'

let FlightForm = (props) => {
  return '';
//   const auth0 = useAuth0Combined()
//   const queryClient = useQueryClient()
//   const [goBack, setGoBack] = useState(false);
//   const { dataTracks } = useQueryJsonDataFactory('tracks', auth0);
//   const mutateTracks = useMutateJsonDataFactory('tracks', auth0, queryClient);
//   const {dataFlights, ...queryFlights } = useQueryJsonDataFactory('flights', auth0);
//   const mutateFlights = useMutateJsonDataFactory('flights', auth0, queryClient);
//   const [ index, setindex ] = useState(null);
//   const [ id ] = useState(props.id ? props.id : nanoid(11));

//   const onSubmit = async values => {
//     if (props.id) {
//       // If we have an id we are modifying an existing flight
//       props.flightUpdate(auth0, props.id, values, )
//       // show toast
//     }
//     else {
//       props.flightCreate(auth0, values)
//       // show toast
//     }
//   }

//   const onGoBack = async () => {
//     setGoBack(true)
//   }

//   const onDelete = async () => {
//     props.flightDelete(auth0, props.id)
//     setGoBack(true)
//   }

//   if (
//     goBack
//   ) {
//     // show toast
//     return <Redirect to='/flights' />
//   }

//   const initialValues = (props.flights && props.id && props.flights[props.id].doc) || null
// {/* crew<br />
//       passengers<br />
//       highest temp<br />
//       highest altitude<br />
//       ground distance<br /> */}
//   return (
      
//     <div>
//       <Form
//       onSubmit={onSubmit}
//       initialValues={initialValues}
//       render={({ handleSubmit, form, submitting, pristine, values }) => (
//         <form onSubmit={handleSubmit}>
//           <div>
//              <label>Balloon</label>
//              <Field name="balloon" component="select">
//               <option />
//               <option value="guilty">Guilty</option>
//               <option value="the5th">The 5th</option>
//             </Field>
//           </div>
//           <div>
//             <label>Date</label>
//             <Field
//               name="date"
//               component="input"
//               type="date"
//               placeholder="Date "
//             />
//           </div>
//           <div>
//             <label>Tracks</label>
//             <Field name="tracks" component="select" type="select" multiple>
              
//               {props.tracks && Object.entries(props.tracks).map(([key, value]) => {
//                 if (value.id === "loaded") return ''
//                 return (
//                   <option
//                     key={value.id}
//                     value={value.id}
//                   >
//                     {new Date(value.doc.timestamp).toLocaleString()}
//                   </option>
//                 )
//               })}
//             </Field>
//           </div>
//           <div>
//             <label>Notes</label>
//             <Field name="notes" component="textarea" placeholder="Notes" />
//           </div>
//           <div className="buttons">
//             <button type="submit" disabled={submitting || pristine}>
//               Submit
//             </button>
//             <button
//               type="button"
//               onClick={onGoBack}
//             >
//               Go Back
//             </button>
//             <button
//               type="button"
//               onClick={onDelete}
//             >
//               Delete
//             </button>
//           </div>
//           {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
//         </form>
//       )}
//     />
//     </div>
//   )
};


export default FlightForm;
