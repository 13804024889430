import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import ErrorBoundary from "../components/ErrorBoundary";
import L from "leaflet";
// import MapToolbar from '../components/MapToolbar'
import MapEvents from "../components/MapEvents";
import MapStats from "../components/MapStats";
import MapMouseLocation from "../components/MapMouseLocation";
import LocateControl from "../components/LocateControl";
// import ActiveUserTrack from '../components/ActiveUserTrack'
// import TeamDisplay from '../components/TeamDisplay'
import ActiveOverlays from "../components/ActiveOverlays";
import Ruler from "../components/ReactLeafletRuler";
import TasksheetDashboard from "../components/TasksheetDashboard";
import { useAppStatusContext } from "../utils/app-status-context";

import { makeStyles } from "@material-ui/core/styles";
import ActiveTasksheets from "../components/ActiveTasksheets";
// import RingsDashboard from '../components/RingsDashboard';

/** Screens/Routes */
// import Map from './screens/Map'
import FlightList from "./FlightList";
import FlightCreate from "./FlightCreate";
import FlightEdit from "./FlightEdit";
import ExternalApi from "./ExternalApi";
import TasksheetList from "./TasksheetList";
import TasksheetEdit from "./TasksheetEdit";
import OverlayList from "./OverlayList";
import OverlayEdit from "./OverlayEdit";
import Shop from "./Shop";
import Settings from "./Settings";
import Help from "./Help";
import Teams from "./Teams";

import { Route, Switch } from "react-router-dom";
import RouteDrawer from "../components/RouteDrawer";
import NavigationDrawer from "../components/NavigationDrawer";
import PrivateRoute from "../components/PrivateRoute";

/**
 * getBoundsFromOperatingLocation
 */
import { useAuth0Combined } from "../hooks/useAuth0Combined";
import { useQueryFactory } from "../queries/query-factory";
import { utmToLatLng } from "../utils/utm";

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "gray",
  },
}));

const mapProps = {
  center: {
    lat: 0,
    lng: 0,
  },
  zoom: 2,
  api: {
    key: "AIzaSyBYJVelXwtOcGtljED8LVSemYHB1fprCko",
    language: "en",
  },
};

function getBoundsFromOperatingLocation(operatingLocation) {
  if (operatingLocation) {
    const latLngOperatingLocation = utmToLatLng(operatingLocation);
    console.log(latLngOperatingLocation);
    return L.latLng(latLngOperatingLocation).toBounds(70000);
    // debugger
  }
  return "";
}

function MapView(props) {
  const auth0 = useAuth0Combined();
  const { data: settings } = useQueryFactory("settings", auth0);
  const { operatingLocation } = settings ? settings.data : {};
  const classes = useStyles(props);
  // const [ currentMouseLocation, setCurrentMouseLocation ] = useState({lat:0,lng:0})
  // const [ currentSavedLocation, setCurrentSavedLocation ] = useState({lat:0,lng:0})
  const { activeTasksheets } = useAppStatusContext();
  // const navigationRef = useRef();
  const [resetMap, setResetMap] = useState(false);
  // const map = useMap();
  // var southWest = L.latLng(41.351127, -93.549842),
  // northEast = L.latLng(41.370582, -93.521327),
  // mybounds = L.latLngBounds(southWest, northEast);
  // var mybounds = L.latLngBounds([41.370582, -93.521327],[41.351127, -93.521327],[41.351127, -93.549842],[41.370582, -93.549842]);
  const tileLayerBounds = getBoundsFromOperatingLocation(operatingLocation);

  // useEffect(() => {
  //   if (!navigationRef.current) return;
  //   // eslint-disable-next-line no-undef
  //   L.DomEvent.disableScrollPropagation(navigationRef.current);
  //   // L.DomEvent.disableClickPropagation(navigationRef.current);
  //   L.DomEvent.on(navigationRef.current, 'dblclick mousemove drag', function (ev) {
  //     L.DomEvent.stopPropagation(ev);
  // });
  // }, []);

  const handleResetMap = () => {
    setResetMap(true);
    setTimeout(() => {
      setResetMap(false);
    }, 1000);
  };

  // const tasksheets = useQueryJsonDataFactory('tasksheets', auth0);

  // const mapContainerRef = useRef(null);
  // let activeTrackPolyLine = null

  try {
    window.onerror = (err) => {
      console.log("ERRRROR");
      console.error(err);
      return true;
    };

    // const userMarker = false && userCurrentLocation ?
    //   <PilotMarker
    //     key="pilotMarker"
    //     lat={userCurrentLocation.lat}
    //     lng={userCurrentLocation.lng}
    //   />
    //   : null

    // if (props.isTracking) {
    // renderUserTrack()
    // }

    if (resetMap) return "Resetting Map...";
    return (
      <MapContainer
        center={mapProps.center}
        zoom={mapProps.zoom}
        className={classes.mapContainer}
        zoomControl={false}
      >
        <button
          style={{
            zIndex: 5000,
            width: 100,
            background: "red",
            position: "absolute",
            right: "15%",
          }}
          onClick={handleResetMap}
        >
          RESET
        </button>
        <NavigationDrawer />
        <Switch>
          <Route path="/" exact />
          <RouteDrawer path="/flights/" exact component={FlightList} />
          <RouteDrawer path="/flights/edit/:id" exact component={FlightEdit} />
          <RouteDrawer path="/flights/create" exact component={FlightCreate} />
          <RouteDrawer path="/tasksheets/" exact component={TasksheetList} />
          <RouteDrawer
            path="/tasksheets/edit/"
            exact
            component={TasksheetEdit}
          />
          <RouteDrawer
            path="/tasksheets/edit/:id"
            exact
            component={TasksheetEdit}
          />
          <RouteDrawer path="/overlays/" exact component={OverlayList} />
          <RouteDrawer path="/overlays/edit/" exact component={OverlayEdit} />
          <RouteDrawer
            path="/overlays/edit/:id"
            exact
            component={OverlayEdit}
          />
          <RouteDrawer path="/teams/" exact component={Teams} />
          <RouteDrawer path="/shop" component={Shop} />
          <RouteDrawer path="/settings" exact component={Settings} />
          <RouteDrawer path="/help" exact component={Help} />
          <Route path="/apitest" exact component={ExternalApi} />
          <PrivateRoute exact path="/profile" component={ExternalApi} />
        </Switch>
        <MapEvents />
        <ZoomControl position="topright" />
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          tileSize="512"
          zoomOffset={-1}
          // minZoom="1"
          // crossOrigin={true}
          // updateWhenIdle={false}
          // updateWhenZooming={false}
          updateInterval={500}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {tileLayerBounds && (
          <TileLayer
            attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">© MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>'
            // url="https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoiemVyZWt3ZWx6IiwiYSI6ImNqczd1ZzNlOTBnNWMzeWxpeXYyeXlzYnkifQ.Py7zUoKirbI74YFAIfHhuQ"

            url="https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}@2x.jpg?key=Y75NsAidCZpv0xEUydMt"
            tileSize="512"
            zoomOffset={-1}
            minZoom="1"
            // crossOrigin={true}
            updateWhenIdle={false}
            updateWhenZooming={false}
            updateInterval={500}
            // TODO: set bounds to only load tiles within operating location
            bounds={tileLayerBounds}
            // bounds={[
            //   [35.252, -106.801],
            //   [35.041, -106.477]
            // ]}
            keepBuffer={5}
            noWrap={true}
            // This should be the default tileset in the future. The above tileset from mapbox should be for paid plans.
            // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
        )}

        <ErrorBoundary>
          <LocateControl
            // locateOptions could probably be removed?
            locateOptions={{
              enableHighAccuracy: false,
              maxZoom: 13,
            }}
            enableHighAccuracy="false"
            maxZoom="13"
            position="topright"
            // startDirectly={true}
            flyTo={true}
            clickBehavior={{
              inView: "setView",
              outOfView: "setView",
              inViewNotFollowing: "inView",
            }}
            showPopup={false}
            initialZoomLevel={14}
          />
          <Ruler />
          <ActiveTasksheets tasksheets={activeTasksheets} />
          <TasksheetDashboard tasksheets={activeTasksheets} />
          <MapStats />
          <MapMouseLocation />
          {/* <TeamDisplay socket={socket} /> */}
          {/* <MapToolbar map={map} /> */}
          <ActiveOverlays />
        </ErrorBoundary>
        {/* <RingsDashboard /> */}
      </MapContainer>
    );
  } catch (err) {
    console.log(err);
    return (
      <div>
        <h1>Error rendering map!</h1>
        <h2>Error: ${err.message}</h2>
      </div>
    );
  }
}

export default MapView;
