import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { useAuth0Combined } from '../hooks/useAuth0Combined';
// import { makeStyles } from '@material-ui/core/styles';
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import AddIcon from '@material-ui/icons/Add';
// import StarsIcon from '@material-ui/icons/Stars';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
// import EditIcon from '@material-ui/icons/Edit';
import api from '../utils/api'

// const useStyles = makeStyles((theme) => ({
// }));

let Shop = (props) => {
  // const classes = useStyles();
  const [ snackbarMessage, setSnackbarMessage ] = useState('')
  const snackbarOpen = snackbarMessage ? true : false
  const { user, getTokenSilently } = useAuth0Combined()
  const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  const monthlyPriceId = 'price_1Gzb9fBFy4KuB19bobxhZ2uU'
  const yearlyPriceId = 'price_1Gzb9fBFy4KuB19bvkaseUhW'
  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id")

  useEffect(() => {
    if (sessionId) {
      setSnackbarMessage('Purchase successful. Thank you!')
    }
    const script = document.createElement('script')
    // <script src="https://js.stripe.com/v3/"></script> 
    script.src = "https://js.stripe.com/v3/"
    script.async = true
  
    document.body.appendChild(script)
  
    return () => {
      document.body.removeChild(script)
    }
  }, [sessionId]);
  
  // https://github.com/stripe-samples/checkout-single-subscription/blob/master/client-and-server/client/script.js

  // Create a Checkout Session with the selected plan ID
  const createCheckoutSession = async (priceId) => {
    console.log('createCheckoutSession')
    const token = await getTokenSilently()
    const auth0 = { user, token }

    console.log(auth0)
    const response = await api.post('/shop/create-checkout-session', {
        priceId: priceId
      }, {
      headers: {
        Authorization: `Bearer ${auth0.token}`
        // "Content-Type": "application/json"
      }
    })
    console.log(response)

    const stripe = Stripe(publishableKey) // eslint-disable-line
    // Call Stripe.js method to redirect to the new Checkout page
    stripe
    .redirectToCheckout({
      sessionId: response.data.sessionId,
    })
    .then(handleResult);

  };

  // Handle any errors returned from Checkout
  const handleResult = function(result) {
    console.log(result)
    if (result.error) {
      setSnackbarMessage("ERROR: " + result.error.message)
    }
    else {

      // send result back to server
      // verify account on server
      // display success message
      setSnackbarMessage("SUCCESS: " + result.error.message)
    }
  };

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarMessage('')
  }

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        autoHideDuration={5000}
        message={snackbarMessage}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
      />
      <Card>
        <Button
          onClick={()=>createCheckoutSession(monthlyPriceId)}
        >Monthly</Button>
      </Card>
      <Card>
        <Button
          onClick={()=>createCheckoutSession(yearlyPriceId)}
        >Yearly</Button>
      </Card>
    </React.Fragment>
  )
}

// You have to connect() to any reducers that you wish to connect to yourself
Shop = connect(
  state => ({
  }),
  { 
    // flightsFetch,
    // tracksFetch,
  }, 
)(Shop);

export default Shop