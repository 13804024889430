import React from 'react'
import { useAuth0Combined } from '../hooks/useAuth0Combined';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import StarsIcon from '@material-ui/icons/Stars';
import EditIcon from '@material-ui/icons/Edit';
import ListItemText from '@material-ui/core/ListItemText';
import { NavLink } from 'react-router-dom'
import { useQueryJsonDataFactory } from '../queries/query-jsonData-factory';
import { nanoid } from 'nanoid/non-secure';

let OverlayList = (props) => {
  const auth0 = useAuth0Combined();
  const { data: overlays, ...overlaysQuery } = useQueryJsonDataFactory('overlays', auth0);

  return (
    <List>
      <NavLink to={`/overlays/edit/${nanoid(11)}`}>
        <ListItem dense button>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={'Add new overlay'} />
        </ListItem>
      </NavLink>
      {/*TODO: sort by active */}
      {overlaysQuery.isSuccess && Object.entries(overlays.data).map(([key, value]) => {

        if (value.id === "loaded") return ''
        const listItemLabel = value.doc.name || value.doc.date
        const icon = value.doc.isActive ? <StarsIcon /> : <EditIcon />
        return (
          <NavLink key={value.id} to={`/overlays/edit/${value.id}`}>
            <ListItem dense button>
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                <ListItemText primary={`${listItemLabel}`} />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  )
}

export default OverlayList