import React, { useEffect, useRef } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import L from 'leaflet';

import { Link as RouterLink } from 'react-router-dom'

import { useAuth0Combined } from '../hooks/useAuth0Combined';

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  navDrawer: {
    zIndex: theme.zIndex.drawer,
  },
  menuButton: {
    position: 'fixed',
    top: 5,
    left: 5,
    zIndex: theme.zIndex.drawer + 1000,
  },
  hidden: {
    display: 'none'
  }
}));

export default function NavigationDrawer() {
  const { user, isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0Combined();
  const classes = useStyles();
  const [state, setState] = React.useState({
    open: false
  });
  const drawerRef = useRef(null);

  /**
   * TODO: these are currently being applied at the Map compoenent level but it would probably make more sense to be
   * applied here.
   * For some reason drawerRef is never being set correctly though.
   **/ 
  // console.log('drawerRef')
  // console.log(drawerRef)
  // useEffect(() => {
  //   try {
  //   // eslint-disable-next-line no-undef
  //   // L.DomEvent.disableClickPropagation(drawerRef.current);
  //   // eslint-disable-next-line no-undef
  //   L.DomEvent.on(drawerRef.current, 'dblclick mousemove drag', function (ev) {
  //     L.DomEvent.stopPropagation(ev);
  //   });
  //   // eslint-disable-next-line no-undef
  //   L.DomEvent.disableScrollPropagation(drawerRef.current);

  //   }
  //   catch (err) {

  //   }
  // }, [drawerRef.current]);

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, open: !state.open });
  };

  return (
    <React.Fragment>
      <Fab
        className={clsx(classes.menuButton )}
        onClick={toggleDrawer}
        color="primary"
      >
        <MenuIcon />
      </Fab>
      {!isAuthenticated && (
        <Fab
          className={clsx(classes.menuButton )}
          onClick={() => loginWithRedirect({})}
          color="primary"
        >
          Sign In!
        </Fab>
      )}
      <Drawer 
        ref={drawerRef}
        open={state.open} 
        onClose={toggleDrawer} 
        className={clsx(classes.navDrawer)}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div
          className={clsx(classes.list)}
          role="presentation"
          onClick={toggleDrawer}
          onKeyDown={toggleDrawer}
        >
        <List>
          <Link component={RouterLink} to="/">
            {!isAuthenticated && (
              <ListItem button onClick={() => loginWithRedirect({})}>
                {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                <ListItemText primary={'Sign In'} />
              </ListItem>
            )}
            <ListItem button>
            {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
            {/* <ListItemText primary={'Map'} /> */}
              Map
            </ListItem>
          </Link>
          {isAuthenticated && (
            <React.Fragment>
              {/* <Link component={RouterLink} to="/flights">
                <ListItem button>
                    Flights
                </ListItem>
              </Link> */}
              <Link component={RouterLink} to="/tasksheets">
                <ListItem button>
                    Tasksheets
                </ListItem>
              </Link>
              <Link component={RouterLink} to="/overlays">
                <ListItem button>
                    Overlays
                </ListItem>
              </Link>
              {/* <Link disabled component={RouterLink} to="/teams">
                <ListItem button>
                    Teams
                </ListItem>
              </Link> */}
            </React.Fragment>
          )}
        </List>
        <Divider />
        <List>
          {isAuthenticated && (
            <Link component={RouterLink} to="/settings">
              <ListItem button>
                <ListItemText primary={'Settings'} />
              </ListItem>
            </Link>
          )}
          {/* <Link component={RouterLink} to="/shop">
            <ListItem button>
              <ListItemText primary={'Shop'} />
            </ListItem>
          </Link> */}
          <Link component={RouterLink} to="/help">
            <ListItem button>
              <ListItemText primary={'Help'} />
            </ListItem>
          </Link>
        </List>
        <Divider />
        <List>
          {/* <Link component={RouterLink} to="/"> */}
          
          {isAuthenticated && !isLoading && (
            <ListItem button onClick={() => logout()}>
              <ListItemText primary={`Howdy, ${user.nickname}`} />
              {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
              <ListItemText primary={'Sign Out'} />
            </ListItem>
          )}
          {/* </Link> */}
        </List>
      </div>
      </Drawer>
    </React.Fragment>
  )
}
