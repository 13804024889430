/* eslint-disable */
import React from 'react'
import { useAuth0Combined } from '../hooks/useAuth0Combined';
// import { flightsFetch } from '../ducks/flights';
// import { tracksFetch } from '../ducks/tracks';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AddIcon from '@material-ui/icons/Add';
import { NavLink } from 'react-router-dom'
import { useQueryJsonDataFactory, useMutateJsonDataFactory } from '../queries/query-jsonData-factory';
import { useQueryClient } from 'react-query';
import { nanoid } from 'nanoid/non-secure';

// const useStyles = makeStyles((theme) => ({
// }));

let FlightList = (props) => {
  const auth0 = useAuth0Combined()
  // const queryClient = useQueryClient()
  // const {dataFlights } = useQueryJsonDataFactory('flights', auth0);
  // const mutateFlights = useMutateJsonDataFactory('flights', auth0, queryClient);
  console.log('flightlist')

  return (
    <List component="nav">
      <NavLink to={`/flights/edit/${nanoid(11)}`}>
        <ListItem dense button>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={'Create new flight'} />
        </ListItem>
      </NavLink>
      {props.flights && Object.entries(props.flights).map(([key, value]) => {
        if (value.id === "loaded") return ''
        return (
          <ListItem key={value.id} dense button>
            <NavLink to={`/flights/edit/${value.id}`}>
              <ListItemText primary={`Date: ${value.doc.date}`} />
            </NavLink>
          </ListItem>
        );
      })}
    </List>
  )
}

export default FlightList