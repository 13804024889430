import axios from "axios";

export const getDistance = (from, to) => {
  const distance = from.distanceTo(to).toFixed(0);
  return distance;
};

/**
 * Get bearings from a given LatLng to another LatLng, optional.
 *
 * @private
 * @param {LatLng} from
 * @param {LatLng} to
 * @param {string} compass
 * @returns {object} bearings and backBearings degrees within range 0..360.
 */
export const bearingsToPreferredCompass = (
  from,
  to,
  compass,
  magDeclination
) => {
  let bearing, backBearing, bearingLabel;
  if (compass === "true") {
    bearingLabel = "TC";
    bearing = getBearing(from, to);
    backBearing = getBearing(to, from);
  } else if (compass === "magnetic") {
    bearingLabel = "MC";
    bearing = wrap360(
      formatNumber(Number(getBearing(from, to)) + Number(magDeclination), 0)
    );
    backBearing = wrap360(
      formatNumber(Number(getBearing(to, from)) + Number(magDeclination), 0)
    );
  }
  return {
    bearing,
    backBearing,
    bearingLabel,
  };
};

//http://www.movable-type.co.uk/scripts/latlong.html
export const getBearing = function (from, /*LatLng*/ to) /*->Double*/ {
  // const R = 6378137, // earth radius in meters
  const d2r = Math.PI / 180, // Degrees to Radian
    r2d = 180 / Math.PI, // Radian to degrees
    // dLat = (to.lat - from.lat) * d2r,
    dLon = (to.lng - from.lng) * d2r,
    lat1 = from.lat * d2r,
    lat2 = to.lat * d2r,
    x =
      Math.cos(lat1) * Math.sin(lat2) -
      Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon),
    y = Math.sin(dLon) * Math.cos(lat2),
    bearing = wrap360(Math.atan2(y, x) * r2d);

  return Number(bearing).toFixed(0);
};

/**
 * Constrain degrees to range 0..360 (e.g. for bearings); -1 => 359, 361 => 1.
 *
 * @private
 * @param {number} degrees
 * @returns degrees within range 0..360.
 */
export const wrap360 = (degrees) => {
  if (0 <= degrees && degrees < 360) return degrees; // avoid rounding due to arithmetic ops if within range
  return ((degrees % 360) + 360) % 360; // sawtooth wave p:360, a:360
};

export const formatNumber = (num, dec) => {
  return Number(num).toFixed(dec);
};

export const measurementToPreferredUnits = (measurement, preferredUnits) => {
  let preferredMeasurementValue = null;
  if (preferredUnits === "m") {
    preferredMeasurementValue = measurement;
  } else if (preferredUnits === "ft") {
    preferredMeasurementValue = formatNumber(measurement * 3.281, 2);
  } else if (preferredUnits === "km") {
    preferredMeasurementValue = formatNumber(measurement / 1000, 2);
  } else if (preferredUnits === "mi") {
    preferredMeasurementValue = formatNumber(measurement / 1609, 2);
  }
  return `${preferredMeasurementValue} ${preferredUnits}`;
};

export const getMagneticNorthDeclination = async (lat, lng) => {
  const noaaResult = await axios.get(
    `https://www.ngdc.noaa.gov/geomag-web/calculators/calculateDeclination?lat1=40&lon1=-105.25&key=zNEw7&resultFormat=json`
  );
  return noaaResult.data.result[0].declination;
};
