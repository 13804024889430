import React from 'react'
import FlightForm from '../components/FlightForm'

const FlightCreate = () => {
  return (
    <React.Fragment>
      <FlightForm />
      Balloon<br />
      Date<br />
      time<br />
      tracks<br />
      notes<br />
      crew<br />
      passengers<br />
      highest temp<br />
      highest altitude<br />
      ground distance<br />
    </React.Fragment>
  )
}

export default FlightCreate 