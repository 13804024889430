import React, { useState, useEffect } from 'react'
import ReactLeafletKml from 'react-leaflet-kml'
import ReactLeafletGpx from './ReactLeaflet/react-leaflet-gpx'
import loadKml from '../utils/loadKml'
import { useAppStatusContext } from '../utils/app-status-context';

const FILE_TYPES = {
  kml: 'kml',
  gpx: 'gpx',
  img: 'img'
};

const ActiveOverlays = () => {
  const { activeOverlays } = useAppStatusContext();
  // const activeOverlays = settings.activeOverlays
  let overlays = []
  if (!activeOverlays || activeOverlays.length === 0) return ''
  Object.entries(activeOverlays).forEach(([key, overlay]) => {
    if (!overlay.doc || !overlay.doc.url) return
    overlays.push(<Overlay key={overlay.doc.id || overlay.doc.name || overlay.doc.color} url={overlay.doc.url} overlay={overlay.doc} />)
  })

  return overlays;
}

export const Overlay = (props) => {
  const { url, overlay } = props;
  const [ kmlData, setKmlData ] = useState(null);
  const [ gpxData, setGpxData ] = useState(null);

  useEffect(() => {
    const type = getType();
    if (type === FILE_TYPES.kml) {
      const fetchKml = async () => {
        try {
          const kmlData = await loadKml(url);
        setKmlData(kmlData);
    }
    catch (err) {
      console.error('ERROR: failed to set KML or GPX data')
      setKmlData(null);
    }
      }
      fetchKml();
    }
    else if (type === FILE_TYPES.gpx) {
      setGpxData(url);
    }
  },[])

  const getType = () => {
    const extension = getExtension(url);

    if (extension === 'kml' || extension === 'kmz') {
      return FILE_TYPES.kml;
    }
    else if (extension === 'gpx') {
      return FILE_TYPES.gpx;
    }
    else if (extension.match('jpg')) {
      return FILE_TYPES.img;
    }
    else {
      return null;
    }
  }

  const getExtension = (filename) => {
    const extensionRegex = /^.*\.(.*)$/;
    const extension = filename.match(extensionRegex) ? filename.match(extensionRegex)[1] : '';
    return extension.toLowerCase();
  }

  try {
    if (kmlData) {
      // return <GeoJSON data={geoj} />
      return <ReactLeafletKml kml={kmlData} />
    }
    else if (gpxData) {
      // console.log('gpxData found')
      return <ReactLeafletGpx gpx={gpxData} overlay={overlay} />
    }
    return ''
  }
  catch (err) {
    console.error('ERROR: failed to parse overlay')
    return '';
  }
}

export default ActiveOverlays;