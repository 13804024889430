import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import Toolbar from '@material-ui/core/Toolbar';
// import Chip from '@material-ui/core/Chip';
import CoordinatesChips from './MapChips/CoordinatesChips'
import AltitudeChips from './MapChips/AltitudeChips'
import SpeedChips from './MapChips/SpeedChips'

const useStyles = makeStyles((theme) => ({
  mapStatsBar: {
    zIndex: 999,
    position: 'fixed',
    // display: 'flex',
    justifyContent: 'center',
    // flexWrap: 'wrap',
    top: 'auto',
    bottom: 70,
    right: 0,
    '& > * > *': {
      margin: theme.spacing(0.2),
    }
  },
}));

function MapStats(props) {
  const classes = useStyles();

  return (
    <div className={classes.mapStatsBar} >
     
      <SpeedChips />
      <br />
     
      <AltitudeChips />
      
      <CoordinatesChips />
    </div>
  )
}

export default MapStats