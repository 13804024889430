import React, { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0Combined } from '../hooks/useAuth0Combined';
import { useQueryFactory } from '../queries/query-factory';
// import Utm, { LatLon } from 'geodesy/utm.js';
import { latLngToUtm, Highlighted4x4 } from '../utils/utm';
import { formatNumber } from '../utils/numberUtil';
import { CircleMarker, useMap, useMapEvents } from 'react-leaflet'
import L from 'leaflet';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  mapMouseLocations: {
    zIndex: 999,
    position: 'fixed',
    // display: 'flex',
    justifyContent: 'center',
    // flexWrap: 'wrap',
    top: 'auto',
    bottom: 30,
    right: 150,
    '& > * > *': {
      margin: theme.spacing(0.2),
    },
    background: "#fff",
    cursor: 'default'
  },
  highlightedUTM: {
    fontSize: '1.4em',
    margin: '0 1px'
  }
}));

function MapMouseLocation(props) {
  const classes = useStyles();
  const auth0 = useAuth0Combined();
  const { data: settings } = useQueryFactory('settings', auth0);
  const controlRef = useRef();
  const map = useMap();
  useMapEvents({
    move: () => {
      setCurrentMouseLocation(map.getCenter());
    }
  });
  const mapCenter = map.getCenter();
  const [ currentMouseLocation, setCurrentMouseLocation ] = useState({lat:0,lng:0})
  const [ currentSavedLocation, setCurrentSavedLocation ] = useState({lat:0,lng:0})

  const { coordinates:coordinatesSettings } = settings ? settings.data : {};

  

  let coordinates
  // console.log(new LatLon(mapCenter.lat, mapCenter.lng).toUtm())
  // console.log(new Utm(new LatLon(mapCenter.lat, mapCenter.lng).toUtm()))
  // console.log(new LatLon(currentMouseLocation.lat, currentMouseLocation.lng).toMgrs())

  useEffect(() => {
    // eslint-disable-next-line no-undef
    L.DomEvent.disableClickPropagation(controlRef.current);
    // eslint-disable-next-line no-undef
    L.DomEvent.disableScrollPropagation(controlRef.current);
  });

  // console.log(mapCenter)
  // console.log(currentSavedLocation)
  if (coordinatesSettings === 'latlng') {
    coordinates = (
      <React.Fragment>
        <span>Mouse - Lat: {formatNumber(currentMouseLocation.lat, 3)} Lng: {formatNumber(currentMouseLocation.lng, 3)}</span>
        <br />
        <span>Saved - Lat: {formatNumber(currentSavedLocation.lat, 3)} Lng: {formatNumber(currentSavedLocation.lng, 3)}</span>
      </React.Fragment>
    )
  } 
  else if (coordinatesSettings === 'utm') {
    const mapCenterMgrs = latLngToUtm(mapCenter);
    const savedMgrs = latLngToUtm(currentSavedLocation);
    const { zone: mouseZone, band: mouseBand, easting: mouseEasting, northing: mouseNorthing } = mapCenterMgrs;
    const { zone: savedZone, band: savedBand, easting: savedEasting, northing: savedNorthing } = savedMgrs;
    coordinates = (
      <React.Fragment>
        <div>
          Mouse - {mouseZone}{mouseBand} <Highlighted4x4 coordinates={mouseEasting} isEasting={true} /> <Highlighted4x4 coordinates={mouseNorthing} isEasting={false} />
        </div>
        <div>
          Saved - {savedZone}{savedBand} <Highlighted4x4 coordinates={savedEasting} isEasting={true} /> <Highlighted4x4 coordinates={savedNorthing} isEasting={false} />
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <CircleMarker
        center={mapCenter}
        radius={2}
        color={'#f00'}
        fillOpacity={'1'}
        weight={.5}
      />
      <div
        className={classes.mapMouseLocations}
        ref={controlRef}
      >
        <Button
          // className={classes.rulerControlButton}
          color="primary"
          onClick={() => {
            setCurrentSavedLocation(mapCenter)
          }}
        >
          save
        </Button>
        {coordinates}
      </div>
    </React.Fragment>
  )
}

export default MapMouseLocation