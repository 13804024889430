import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import LinearProgress from '@material-ui/core/LinearProgress'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useAppStatusContext } from '../utils/app-status-context';

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  loadingCard: {
    maxWidth: 250
  },
  loadingBackdrop: {
    zIndex: theme.zIndex.drawer + 5000,
    color: '#fff',
  },
}))

const LoadingBackdrop = (props) => {
  const classes = useStyles()
  const { showLoading } = useAppStatusContext();
  const showMessage = showLoading && showLoading.message;
  const showProgress = showLoading && showLoading.progress;
  const open = !!showMessage || !!showProgress;

  const message = showMessage ?
    <Typography variant="body2" component="p">{showMessage}</Typography>
    : ''
  const progress = showProgress ?
    <LinearProgress variant="determinate" value={showProgress} />
    : ''

  if (!open) return '';
  return (
    <div>
      <Backdrop className={classes.loadingBackdrop} open={open}>
        <Card className={classes.loadingCard} variant="outlined">
          <CardContent>
            <CircularProgress color="inherit" />
            {message}
            {progress}
          </CardContent>
        </Card>
      </Backdrop>
    </div>
  )
}


export default LoadingBackdrop;