import React, { useRef, useEffect } from 'react'
import { Route } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import L from 'leaflet';

const useStyles = makeStyles((theme) => ({
  routeDrawer: {
    // marginTop: 40,
    // backgroundColor: 'blue'
    // display: 'flex',
    // flexWrap: 'wrap',
    // '& > *': {
      // margin: theme.spacing(1),
      // width: theme.spacing(16),
      // height: theme.spacing(16),
    // },
  },
  innerDrawer: {
    marginTop: 45
  }
}));

const RouteDrawer = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  const drawerRef = useRef();

  useEffect(() => {
    if (!drawerRef.current) return;
    // eslint-disable-next-line no-undef
    L.DomEvent.disableScrollPropagation(drawerRef.current);
    // eslint-disable-next-line no-undef
    // L.DomEvent.disableClickPropagation(drawerRef.current);
    L.DomEvent.on(drawerRef.current, 'dblclick drag', function (ev) {
      // console.log('domEvent')
      // console.log(ev)
      L.DomEvent.stopPropagation(ev);
  });
  }, [drawerRef.current]);

  return (
    <Route
      {...rest}
      render={routeProps => (
        <Drawer
          open={true}
          variant="persistent"
          className={classes.routeDrawer}
          ref={drawerRef}
        >
          <div
            className={classes.innerDrawer}
            role="presentation"
          >
            <Component {...routeProps} />
          </div>
        </Drawer>
      )}
    />
  )
}

export default RouteDrawer 