import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { useAuth0Combined } from '../hooks/useAuth0Combined';
import { useAppStatusContext } from '../utils/app-status-context';
import { useQueryJsonDataFactory } from '../queries/query-jsonData-factory';
import io from 'socket.io-client';

export const UserLocationContext = React.createContext();
export const useUserLocation = () => useContext(UserLocationContext);
export const UserLocationProvider = ({children}) => {
  const auth0 = useAuth0Combined();
  const [ userCurrentLocation, setUserCurrentLocation ] = useState({lat: 0, lng: 0});
  const [ currentAltitude, setCurrentAltitude ] = useState(0)
  const [ currentSpeed, setCurrentSpeed ] = useState(0)
  const [ currentHeading, setCurrentHeading ] = useState(0)
  const [ currentAccuracy, setCurrentAccuracy ] = useState(0)
  const [ currentAltitudeAccuracy, setCurrentAltitudeAccuracy ] = useState(0)
  const [ activeTrack ] = useState(null)
  const [ followUser ] = useState(false)
  const [ watchUser ] = useState(null)

  const [ socket, setSocket ] = useState()

  const teams = useQueryJsonDataFactory('teams', auth0);
  const { isTracking, addActiveTrackNodes } = useAppStatusContext();

  useEffect(() => {
    initSockets()
  }, [auth0.isLoading])

    // useEffect(() => {
  //   // joinRoom()
  // }, [
  //   teams.teamsActive,
  //   teams.teamsChannelName,
  //   teams.teamsChannelPassword
  // ])

  const initSockets = async () => {
    try {

      if (!auth0.user) return;
      const newSocket = io(process.env.REACT_APP_API_HOST, {
        // query: `token=${token}`,
        extraHeaders: {
          // 'x-clientid': 'abc'
          Authorization: `Bearer ${auth0.token}`
        }
      })
      if (newSocket) setSocket(newSocket)
      // socket.on('connect', () => joinRoom())
      // init socket connections
      // socket.on('locationUpdate', (location) => {
      //   console.log('socket location update')
      //   console.log(location)
      // })
      // socket.on('authenticated', () => {
      //   console.log('socket authorized')
      // })
      // socket.on('unauthorized', (error) => {
      //   if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
      //     // redirect user to login page perhaps?
      //     console.log('User token has expired');
      //   }
      // });
      // console.log('sockets init\'d')
    }
    catch (error) {
      console.log('socket error => ', error)
    }
  };

  // const joinRoom = () => {
  //   if (socket && teams && teams.teamsActive) {
  //     socket.emit('joinRoom', JSON.stringify({roomName: teams.teamsChannelName + teams.teamsChannelPassword}))
  //   }
  // }

  // const handleLocationFound = (e) => {
  //   updateUserLocation(e)
  // };
  /**
    *  This fires twice per position update. Once for previous position and once for current position
    * @param {Object} position 
    */
  const updateUserLocation = (position) => {
    if (!position) return;
    if (isTracking) {
      // TODO: Filter out results that are too far away. Look up best way to achieve consistent result output
      //  *  TODO: track "previousPosition" to compare against and prevent duplicate and errant coords
      // Why do I have both of these?
      addActiveTrackNodes(position)
      // userAddTrackLatLng(position)
    }

    // if teamsActive
    // emit location
    // this might need to be debounced?
    const socketLocation = {
      latlng: position.latlng,
      speed: position.speed,
      heading: position.heading,
      altitude: position.altitude
    }
    // console.log('updateUserLocation')
    if (socket && teams.teamsActive) {
      // console.log(teams)
      // console.log(position)
      const socketPayload = {
        location: socketLocation,
        teamsDisplayName: teams.teamsDisplayName,
        roomName: teams.teamsChannelName + teams.teamsChannelPassword
      }
      socket.emit('locationUpdate', JSON.stringify(socketPayload))
    }

    setUserCurrentLocation(position.latlng)
    setCurrentSpeed(position.speed ? position.speed : 0)
    setCurrentHeading(position.heading ? position.heading : 0)
    setCurrentAltitude(position.altitude ? position.altitude : 0)
    setCurrentAltitudeAccuracy(position.altitudeAccuracy ? position.altitudeAccuracy : null)
    setCurrentAccuracy(position.accuracy ? position.accuracy : null)
  };


  return (
    <UserLocationContext.Provider
      value={{
        updateUserLocation,
        userCurrentLocation,
        currentAltitude,
        currentSpeed,
        currentHeading,
        currentAccuracy,
        currentAltitudeAccuracy,
        activeTrack,
        followUser,
        watchUser,
      }}
    >
      {children}
    </UserLocationContext.Provider>
  );
};