import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { useUserLocation } from '../../hooks/useUserLocation';
import { useAuth0Combined } from '../../hooks/useAuth0Combined';
import { useQueryFactory } from '../../queries/query-factory';
import { latLngToUtm, Highlighted4x4 } from '../../utils/utm';
import { formatNumber } from '../../utils/numberUtil';

// const useStyles = makeStyles((theme) => ({
// }));

function CoordinatesChips(props) {
  const auth0 = useAuth0Combined();
  const { data: settings } = useQueryFactory('settings', auth0);
  const { userCurrentLocation } = useUserLocation();
  const { lat, lng } = userCurrentLocation;
  const { coordinates } = settings ? settings.data : {};
  let chips;

  if (coordinates === 'latlng') {
    chips = (
      <React.Fragment>
        <Chip color="primary" size="small" label={`Lat: ${formatNumber(lat, 3)} `} />
        <br />
        <Chip color="primary" size="small" label={`Lng: ${formatNumber(lng, 3)} `} />
        <br />
      </React.Fragment>
    )
  } 
  else if (coordinates === 'utm') {
    const { zone, band, easting, northing } = latLngToUtm(userCurrentLocation);

    chips = (
      <React.Fragment>
        <Chip color="primary" size="small" label={`${zone}${band}`} />
        <br />
        <Chip color="primary" size="small" label={<Highlighted4x4 coordinates={easting} isEasting={true} />} />
        <br />
        <Chip color="primary" size="small" label={<Highlighted4x4 coordinates={northing} isEasting={false} />} />
        <br />
      </React.Fragment>
    )
  }

  return (
    <div>
      {chips}
    </div>
  )
}

export default CoordinatesChips