import React from 'react'
import TeamsForm from '../components/TeamsForm'

const Teams = () => {
  return (
    <React.Fragment>
      <TeamsForm />
    </React.Fragment>
  )
}

export default Teams